
export default function Solution(props) {
    return ( 
        <><section class="py-28" id="steps">
        <div class="container">
            <div class="row mb-12">
                <div class="col">
                    <h2 class="display-5 font-semibold text-center">GrantMachine edits and writes grants on demand</h2>
                </div>
            </div>
            <div class="row mb-12">
                <div class="col">
                    <h2 class="display-6 text-center">Let GrantMachine do the work for your nonprofit, startup or public sector agency</h2>
                </div>
            </div>
            <br/><br/>
            <div class="row justify-between mb-10">
                <div class="col sm:w-1/12 md:text-center flex-col relative md:order-2">
                    <div class="absolute hidden md:flex top-1 left-1/2 w-0.5 h-full bg-gray-500 z-[0]"></div>
                </div>
                <div class="col md:w-5/12 md:order-last">
                    <div class="flex-auto py-6">
                        <h4 class="mb-3 display-6 text-left"><b>Nonprofits</b></h4><br/><br/>
                        <p class="mb-0 display-6 text-left" >Write more grants in less time with GrantMachine, maximizing your chances of securing crucial funding. Our suite of features streamline the grant writing and editing process so that you can emphasize and concentrate on what matters most to your nonprofit.
                        </p>
                    </div>
                </div>
                <div class="col md:w-3/12 hero container max-w-screen-lg mx-auto text-center pb-10">
                    <img alt="company logo" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/1e1c157f-f625-443e-a3e7-d36b76d372d8.png" width="600" height="600"/>
                </div>
            </div>
            <div class="row justify-between mb-10">
                <div class="col md:w-5/12 md:order-first">
                    <div class="flex-auto py-6">
                        <h4 class="mb-3 display-6 text-left"><b>Startups</b></h4><br/><br/>
                        <p class="mb-0 display-6 text-left">GrantMachine expedites grant writing and editing, ensuring a seamless process that puts your needs first. Amplify your startup's success with GrantMachine's tailored grant assistance, streamlining applications and enhancing your competitive edge.
                        </p>
                    </div>
                </div>
                <div class="col sm:w-1/12 md:text-center flex-col relative md:order-2">
                    <div class="absolute hidden md:flex top-1 left-1/2 w-0.5 h-full bg-gray-500 z-[0]"></div>
                </div>
                <div class="md:order-last col md:w-3/12 hero container max-w-screen-lg mx-auto text-center pb-10">
                    <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/59c9a2c5-af48-4724-aa22-ae73d8c41a2c.png" width="600" height="600"/>
                </div>
            </div>
            <div class="row justify-between mb-10">
                <div class="col sm:w-1/12 md:text-center flex-col relative md:order-2">
                    <div class="absolute hidden md:flex top-1 left-1/2 w-0.5 h-full bg-gray-500 z-[0]"></div>
                </div>
                <div class="col md:w-5/12 md:order-last">
                    <div class="flex-auto py-6">
                        <h4 class="mb-3 display-6 text-left"><b>Public Sector Agencies</b></h4><br/><br/>
                        <p class="mb-0 display-6 text-left">GrantMachine’s automations empower government agencies to compile comprehensive applications efficiently, alleviating administrative burdens. Focus on your agency's mission with GrantMachine's streamlined grant writing, allowing you to prioritize impactful service delivery and efficient funding acquisition.
                        </p>
                    </div>
                </div>
                <div class="col md:w-3/12 hero container max-w-screen-lg mx-auto text-center pb-10">
                    <br/><br/><br/>
                <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/9737d4de-8196-4b1c-a329-c6ba765d328e.png" width="800" height="800"/>
                </div>
            </div>
        </div>
    </section>



    <section class="bg-primary-50 py-12">
    <div class="container">
        <div class="row">
            <div class="col lg:w-4/12 mb-6 lg:mb-0">
                <h2 class="font-semibold display-5 text-dark">Frequently Asked Questions</h2>
            </div>
            <div class="col lg:w-7/12 lg:mx-1/12">
                <div class="bg-white rounded pt-2 px-4 shadow-sm mb-4 py-0.5">
                    <p class="font-semibold text-dark text-4xl">What is GrantMachine?</p>
                    <p class="text-4xl">GrantMachine, is software for grantwriting, enhancing the process of writing and editing grants using artificial intelligence based on a number of sources and grants (previous grants that successfully funded and grants not selected for funding).</p>
                </div>
                <div class="bg-white rounded pt-2 px-4 shadow-sm mb-4 py-0.5">
                    <p class="font-semibold text-dark text-4xl">What does GrantMachine do?</p>
                    <p class="text-4xl">It’s features include scoring responses and suggesting additions and edits to extend your grant responses. With GM you can significantly aid your grantwriting while reinforcing human expertise. It's a robust tool to augment your grantwriting and editing effectively and effortlessly.</p>
                </div>
                <div class="bg-white rounded pt-2 px-4 shadow-sm mb-4 py-0.5">
                    <p class="font-semibold text-dark text-4xl">Can I write all parts of a grant using GrantMachine?</p>
                    <p class="text-4xl">We recommend that you start with an existing answer (even a few sentences will do) as well as some information about your organization. GrantMachine will take it from there.</p>
                </div>
                <div class="bg-white rounded pt-2 px-4 shadow-sm mb-4 py-0.5">
                    <p class="font-semibold text-dark text-4xl">How do I check my grant application progress in ChangeFinder?</p>
                    <p class="text-4xl">To track your grant application progress, engage with relevant platforms, funders, or organizations where you applied. They often share updates on application status. GrantMachine aids in creating application responses. Our platform does not currently include progress monitoring. Join our e-mail list for future platform updates, including progress tracking features in development.</p>
                </div>
                <div class="bg-white rounded pt-2 px-4 shadow-sm mb-4 py-0.5">
                    <p class="font-semibold text-dark text-4xl">How is ChangeFinder different from ChatGPT?</p>
                    <p class="text-4xl">ChangeFinder (CF) is purpose built for grantwriting while ChatGPT (CGT) is a general large language model informed by many data sources that won’t necessarily enhance your grant writing. CF is specially designed for grantwriters, providing unique tools and dedicated support. In contrast, CGT is a basic conversational AI model. Keep in mind, finding CGT's contact support information is elusive and CF is dedicated to assisting you every step of the way. Customer service is what we do utilizing optimization for your application funding needs.</p>
                </div>
            </div>
        </div>
    </div>
</section>


   </>
     );
    }