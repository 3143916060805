export default function Team(props) {

return ( 
<section class="py-28" id="team">
        <div class="container">
            <div class="row">
                <div class="col md:w-8/12 mx-auto text-center mb-12">
                    <h1 class="display-4 font-semibold mb-3">ChangeFinder Team</h1>
                    <p class="text-2xl font-semibold">Our mission is to empower philanthropy, nonprofits, and the communities they serve in tackling the world's most pressing challenges by significantly enhancing grant application success rates.
    <br />
    As a dedicated team of grant writers, program officers, project leads, executive directors, and board members, we deeply understand the importance of being driven by a shared mission.
</p>

                </div>
            </div>
            <div class="row text-center md:text-left">
            <div class="col md:w-8/12 lg:w-6/12 flex flex-col md:flex-row items-center mx-auto mb-6">
                    <img class="w-[150px] mb-5 md:mb-0 lg:w-[200px] lg:h-300" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/161b7e59-dd4f-49aa-a105-b69e53f9756b.png" />
                    <div class="w-full px-5">
                        <h5 class="text-md mb-2 text-4xl">Ian Winbrock</h5>
                        <p class="text-md mb-2 text-4xl"><b>Co-Founder & Chief Executive Officer</b></p>
                        <p class="text-muted text-3xl">Ian leads the product vision and strategy, while growing our sales pipeline. He is passionate about improving the philanthropic funding ecosystem so that nonprofits and foundations can deliver on their missions and advance impact. In his spare time Ian likes to go hiking, backpacking and do bodyweight exercises.</p>
                        <div class="inline-flex flex-row gap-x-3">
                            <a href="https://www.linkedin.com/in/ianwinbrock/" title="linkedin" target="_blank">
                                <i class="fab fa-linkedin text-3xl"></i>
                            </a>
                            <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=winbrock@changefinder.app&su=YourSubject&body=YourEmailBody&tf=cm" title="email" target="_blank"><i class="text-3xl fas fa-envelope fa-3x"></i> </a>
                            <a href="#" title="facebook"></a>
                        </div>
                    </div>
                </div>
                <div class="col md:w-8/12 lg:w-6/12 flex flex-col md:flex-row items-center mx-auto mb-6">
                    <img class="w-[150px] mb-5 md:mb-0 lg:w-[200px]" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/8b3d1f3d-f13a-4b5f-a034-e31f45877c22.png" />
                    <div class="w-full px-5">
                        <h5 class="text-md mb-2 text-4xl">Madeline Tomka</h5>
                        <p class="text-md mb-2 text-4xl"><b>Co-Founder & Chief Operations Officer</b></p>
                        <p class="text-md mb-2 text-3xl">Madeline leads operations for the sales team and is the principal evangelist for our users. She is an active champion of impact philanthropy and remains committed to bridging and strengthening partnerships between those that want to provide funding to those fulfilling mission driven work. Have you ever heard a squirrel impersonation before? Madeline has you covered.</p>
                        <div class="inline-flex flex-row gap-x-3">
                            <a href="https://www.linkedin.com/in/madelinejtomka/" title="linkedin" target="_blank">
                                <i class="fab fa-linkedin text-3xl"></i>
                            </a>
                            <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=madeline@changefinder.app&su=YourSubject&body=YourEmailBody&tf=cm" title="email" target="_blank"><i class="text-3xl fas fa-envelope fa-3x"></i> </a>
                            <a href="#" title="facebook"></a>
                        </div>
                    </div>
                </div>
       
                <div class="col md:w-8/12 lg:w-6/12 flex flex-col md:flex-row items-center mx-auto mb-6">
                    <img class="w-[150px] lg:w-[200px] mb-5 md:mb-0" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/0a60da77-69f5-4c1c-88f0-84494345654d.png" />
                    <div class="w-full px-5">
                        <h5 class="text-md mb-2 text-4xl">Harry Herzberg</h5>
                        <p class="text-md mb-2 text-4xl"><b>Co-Founder & Chief Technology Officer</b></p>
                        <p class="text-md mb-2 text-3xl">Harry translates the product vision into our technology stack, ensuring the software is scalable, secure, and user-friendly while continuously improving the machine learning capabilities of the platform. He is a skilled computer scientist with a background in machine learning (ML) and software development. He has previously written successful ML code for legacy hospitals and secured funding for another ML startup. When he isn’t coding, Harry is competing in Super Smash Bros tournaments in Oregon. </p>
                        <div class="inline-flex flex-row gap-x-3">
                            <a href="https://www.linkedin.com/in/harry-herzberg-ba000814b/" title="linkedin" target="_blank">
                                <i class="fab fa-linkedin text-3xl"></i>
                            </a>
                            <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=harryherzberg@gmail.com&su=YourSubject&body=YourEmailBody&tf=cm" title="email" target="_blank"><i class="text-3xl fas fa-envelope fa-3x"></i> </a>
                            <a href="#" title="facebook"></a>
                        </div>
                    </div>
                </div>
                <div class="col md:w-8/12 lg:w-6/12 flex flex-col md:flex-row items-center mx-auto mb-6">
                    <img class="w-[150px] mb-5 md:mb-0 lg:w-[200px]" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/c142f4a2-b40a-408b-9ab6-a8b0ef372dd9.png" />
                    <div class="w-full px-5">
                        <h5 class="text-md mb-2 text-4xl">Venkata “Sai” Paramkusham </h5>
                        <p class="text-md mb-2 text-4xl"><b>Co-Founder & Chief Product Officer</b></p>
                        <p class="text-md mb-2 text-3xl">Sai ensures that the product vision is executed, leading our product roadmap overseeing the introduction of innovative features, meticulously addressing software bugs, and consistently elevating UI/UX design standards. With a Master's degree in Computer Science and over four years of experience, he has demonstrated unparalleled expertise in a plethora of projects, notably the pioneering development of 5G.</p>
                        <div class="inline-flex flex-row gap-x-3">
                            <a href="https://www.linkedin.com/in/phanisaipvv/" title="linkedin" target="_blank">
                                <i class="fab fa-linkedin text-3xl"></i>
                            </a>
                            <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=phanisaipvv96@gmail.com&su=YourSubject&body=YourEmailBody&tf=cm" title="email" target="_blank"><i class="text-3xl fas fa-envelope fa-3x"></i> </a>
                            <a href="#" title="facebook"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
 );
}