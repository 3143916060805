export default function Nonprofits(props) {
    return ( 
        <div className='page'>
            <main>
                <div className="page__box">
                    <div className="page__page">
                        <p>Grant writing is hard, and grant management and reporting can take valuable time away from your mission. We know because we’ve spent more than 10 years writing dozens of grants for organizations working with communities to advance causes from health equity to food sovereignty, and COVID-19 responses to the future of work. But with ChangeFinder, grant writing, grant management, and grant reporting just became a lot easier..</p>
                        <p>ChangeFinder’s software will match you to giving opportunities that match your organization's capacities, past grants and current needs so that you can apply for grants that you are the most competitive for. Our notification system will make sure that you never submit a late grant application again. ChangeFinder’s autofilled grant application system makes it easy for you to complete grant applications faster while making suggestions for you along the way so that you can compile the most complete and competitive applications possible. Our reporting system makes it easy for you to finalize grant reports for your funders and to their specifications, saving you time and ensuring that you develop long lasting relationships with your organizations funders. </p>
                    </div>
                </div>
            </main>
        </div>
     );
}