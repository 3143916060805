export default function footer() {
    return (
        <>
        <footer class="bg-dark py-12" id="footer">
    <div class="container">
        <div class="row mb-12">
            <div class="col md:w-3/12 md:text-left text-center">
                <p class="mb-3 h4">
                    <a class="mb-6 font-semibold text-white" href="#">ChangeFinder LLC </a>
                </p>
                <div>
                    <p class="text-light">Grant Writing simplified.</p>
                </div>
            </div>
            <div class="col md:w-3/12 text-center mb-6 md:mb-0 flex flex-col gap-y-2">
                <p class="text-light font-semibold mb-3">Product</p>
                <a class="block text-white" href="#/login">Log In</a>
                <a class="block text-white" href="#/payment">Pricing</a>
            </div>
            <div class="col md:w-3/12 text-center mb-6 md:mb-0 flex flex-col gap-y-2">
                <p class="text-light font-semibold mb-3">Company</p>
                <a class="block text-white" href="#/company/team">Our Team</a>
                <a class="block text-white" href="#/company/privacy">Privacy Policy</a>
                <a class="block text-white" href="#/company/licensing">Licensing Agreement</a>
                <a class="block text-white" href="https://forms.gle/aiE5BMfVnjhNcdCe8">Report a bug/Request a feature</a>
            </div>
        </div>
        <div class="row text-white">
            <div class="col text-center w-full">
                <p class="text-sm">© 2023 ChangeFinder LLC. All rights reserved.</p>
            </div>
        </div>
    </div>
</footer>
        </>
    )
}
