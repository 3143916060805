import React, { Component } from "react";
import Dropzone from "../Dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from 'file-saver';
import { getAuth } from "firebase/auth";
import Spacer from "../../tools/Spacer";
import Spinner from "react-bootstrap/Spinner";
//import { htmlDocx } from 'html-docx-js/dist/html-docx';
import axios from "axios";
const htmlDocx= require('html-docx-js/dist/html-docx');
const score = "3/100";
var fullscore = "";
var Myhtml = ""
const openai = require('openai');
// Initialize the OpenAI API client
openai.apiKey = "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";

const rating = [];

// Example usage
const DEFAULT_PARAMS = {
    model: "text-davinci-003",
    temperature: 0.4,
    max_tokens: 2000,
    top_p: 1,
    frequency_penalty: .2,
    presence_penalty: 0,
};

const DEFAULT_PARAMS2 = {
    model: "text-davinci-003",
    temperature: 0.1,
    max_tokens: 3500,
    top_p: 1,
    frequency_penalty: .7,
    presence_penalty: 0,
};

const generatePrompt = (question) => {
    return `Please provide feedback and scores for the following grant proposal in the following categories: overall quality, clarity, feasibility, impact, and innovation.
    also include general information on how their grant could be improved\n
 ${question}
Summary:`;
};


const generatePromptDoc = (question,template) => {
    return `${question}\n${template}\n
     Create an Grant application with the Given information in HTML with only body format. write 500 words detailed essay and with requesting for the grant`;
};


const convertAndDownload = (htmlContent) => {
    console.log("Convert HTML")
    const docx = htmlDocx.asBlob(htmlContent);
    console.log(docx)
    saveAs(docx, 'converted_document.docx');
  };

function extractFeedbackAndScores(text) {
    // Initialize the feedback and scores variables
    let feedback = '';
    const scores = {};

    // Extract the feedback using a regular expression
    const feedbackRegex = /Feedback:([\s\S]*?)Scores:/;
    const feedbackMatch = text.match(feedbackRegex);
    if (feedbackMatch) {
        feedback = feedbackMatch[1].trim();
    }

    // Extract the scores using a regular expression
    const scoresRegex = /Scores:([\s\S]*?)Overall/;
    const scoresMatch = text.match(scoresRegex);
    if (scoresMatch) {
        const scoresText = scoresMatch[1].trim();
        const scoresLines = scoresText.split('\n');
        scoresLines.forEach(line => {
            const scoreRegex = /(\w+): (\d+)/;
            const scoreMatch = line.match(scoreRegex);
            if (scoreMatch) {
                scores[scoreMatch[1]] = parseInt(scoreMatch[2], 10);
            }
        });
    }
    return { feedback, scores };
}

function addCode(Thetext) {
    // Create a new h3 element with the text
    const h3 = document.createElement('h3');
    h3.innerText = Thetext;

    // Append the h3 element to the element with the "add_to_me" ID
    document.getElementById("add_to_me").appendChild(h3);
}




export default class GrantChecker extends Component {
    constructor() {
        super();
        this.state = {
            file: null,
        };
        this.processFile = this.processFile.bind(this);
    }

    savePromptResponse() {
        let saveResponseParams = {
            prompt: this.state.prompt.question,
            answer: this.state.prompt.response,
            suggestion: this.state.rating,
            score: this.state.score,
            feedback: this.state.feedback,

        };

        axios
            .post("/api/prompt-response/store", JSON.stringify(saveResponseParams))
            .then((res) => {
                console.log(res);
            });
    }



    async getRating(params = {}, q) {
        this.setState({ loading: true });
        const auth = getAuth();
        const user = auth.currentUser;
        let temp=`\n Name of Company:${user.data.name} \n Year of foundation: ${user.data.yearFounded}\n Oreganization Mission:${user.data.mission}\n
        EIN Number: ${user.data.EIN}\n NTTE:${user.data.NTTE}\n`
        let tdoc = { prompt: generatePromptDoc(q,temp) };
        const params1_ = { ...DEFAULT_PARAMS, ...params, ...tdoc };
        const requestOptions1 = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + openai.apiKey,
            },
            body: JSON.stringify(params1_),
        };
        const response1 = await fetch(
            "https://api.openai.com/v1/completions",
            requestOptions1
        );
        const data1 = await response1.json();
        console.log("In Doc")
        Myhtml = data1.choices[0].text;
        console.log(Myhtml)

        

        this.setState({ loading: false });
        let t = { prompt: generatePrompt(q) };
        const params_ = { ...DEFAULT_PARAMS, ...params, ...t };
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + openai.apiKey,
            },
            body: JSON.stringify(params_),
        };
        const response = await fetch(
            "https://api.openai.com/v1/completions",
            requestOptions
        );
        const data = await response.json();
        //alert("entered get rating");
        var fullString = data.choices[0].text;

        fullscore = fullString;
        var FeedbackArray = extractFeedbackAndScores(fullString);

        console.log("startin!!!!!")
        console.log("test: ");
        console.log(FeedbackArray[0] + "/10");
        addCode(fullString);
        this.setState({ loading: false });

    }



    processFile(file) {
        // Use the FileReader to read the text file as a string
        console.log('Processing file:', file);
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            // The file's text is stored in the fileReader.result property
            console.log('File loaded:', fileReader.result);
            const text = fileReader.result;
            alert(text);

            // Set the file and text in the component's state
            await this.setState({ file, text });

            // Display an alert with the text
            //await alert(text);
            this.getRating({}, String(text));
        };
        fileReader.readAsText(file);
    }

    /*
    
      //right now can't take pdf files
      processFile(file) {
        // Use the FileReader to read the PDF as an ArrayBuffer
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          // Convert the binary data to a string using btoa
          const pdfText = btoa(new Uint8Array(fileReader.result).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        
          
          // Set the file and pdfText in the component's state
          this.setState({ file, pdfText });
      
          // Display an alert with the PDF text
          alert(pdfText);
          this.getRating({}, "my grant");
        };
        fileReader.readAsArrayBuffer(file);
      }
      """
    */
      render() {
        const { file } = this.state;
        let { rated, loading } = this.state;
        
        return (
            <div className="text-center container mx-auto my-10 p-6 border rounded">
                <h2 className="font-bold my-4 text-xl">Grant Checkr</h2>
                <p className="text-sm my-4">
                    Upload your grant application and receive feedback and suggestions.
                </p>
                <Dropzone process={this.processFile}></Dropzone>
                {loading ? (
                    <>
                        <div style={{display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div className="border mb-5"></div>
                                <Spacer height={"50px"} />
                                <img src="https://shortpixel.com/img/spinner2.gif" width="128" height="128" alt="Loading"/>
                                <Spinner animation="border" />
                            </div>
                        </div>

                    </>
                ) : null}
                {file && (
                    <div className="border-t p-4 rounded bg-gray-100 mt-4">
                        <div id="add_to_me"></div>
                        <div className="container mb-4"></div>
                        <div className="flex justify-between">
                            <div>
                                <button 
                                    className="btn btn-primary mr-2 bg-blue-500 text-white rounded px-3 py-2"
                                    id="downloadSuggestions"
                                    onClick={() => convertAndDownload(Myhtml)}>
                                    Download a template Request for funds proposal!
                                </button>
                                <button
                                    className="btn btn-primary bg-blue-500 text-white rounded px-3 py-2"
                                    id="emailSuggestions">
                                    Email Suggestions
                                </button>
                            </div>
                            <div>
                                <span className="btn btn-success rounded-full mr-2 bg-green-500 p-3">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                </span>
                                <span className="btn btn-danger rounded-full bg-red-500 p-3">
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
