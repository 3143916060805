import React from 'react';
import './LicensingAgreement.css'; // Assuming you want to style using CSS

const LicensingAgreement = () => {
  return (
    <div className="agreement">
      <h1>ChangeFinder Licensing Agreement</h1>

      <p>This Licensing Agreement ("Agreement") is entered into by and between ChangeFinder, a Software as a Service (SAAS) company founded in 2022, with its principal place of business at [777 Jefferson Street, Eugene, OR 97402] ("Licensor"), and the party agreeing to these terms ("Licensee").</p>

      <h2>Definitions</h2>
      <p>a. Software: The AI-powered software provided by Licensor known as "ChangeFinder," including any updates, modifications, or enhancements thereof.</p>
      <p>b. AI Technology: The artificial intelligence algorithms, models, and technologies embedded within the Software.</p>
      <p>c. Licensee: The individual or entity obtaining a license to use the Software under the terms of this Agreement.</p>

      <h2>Grant of License</h2>
      <p>Licensor hereby grants Licensee a non-exclusive, non-transferable license to use the Software and leverage the AI Technology for the purpose of writing grants and assisting foundations in vetting and qualifying grant applications.</p>

      <h2>License Scope</h2>
      <p>a. Licensee may use the Software and the AI Technology solely for its intended purpose as described in Section 2.</p>
      <p>b. Licensee may not modify, distribute, sell, sublicense, or otherwise transfer the Software, the AI Technology, or any rights therein.</p>

      <h2>Intellectual Property</h2>
      <p>a. The Software, the AI Technology, and any related intellectual property rights are and shall remain the exclusive property of Licensor.</p>
      <p>b. Licensee shall not acquire any ownership rights in the Software or the AI Technology except for the limited license granted herein.</p>

      <h2>Confidentiality</h2>
      <p>Licensee agrees to maintain the confidentiality of the Software, the AI Technology, and any proprietary information provided by Licensor, using at least the same degree of care that Licensee uses to protect its own confidential information.</p>

      <h2>Support and Updates</h2>
      <p>Licensor may, at its sole discretion, provide support, updates, or enhancements to the Software. Licensee acknowledges that such support, updates, or enhancements may leverage and improve the AI Technology and are not guaranteed.</p>

      <h2>Fees and Payment</h2>
      <p>a. Licensee agrees to pay the fees for the use of the Software and access to the AI Technology as specified by Licensor.</p>
      <p>b. All fees are non-refundable once payment is made.</p>

      <h2>Termination</h2>
      <p>Licensor may terminate this Agreement at any time for any reason, with or without notice. Upon termination, Licensee must cease all use of the Software, the AI Technology, and destroy all copies in its possession.</p>

      <h2>Governing Law and Jurisdiction</h2>
      <p>This Agreement shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>

      <h2>Entire Agreement</h2>
      <p>This Agreement constitutes the entire agreement between Licensor and Licensee and supersedes all prior discussions, understandings, and agreements.</p>

      <p>By accepting this Agreement, Licensee acknowledges that they have read, understood, and agreed to be bound by its terms.</p>

      <p>Licensor:</p>
      <p>ChangeFinder</p>
      <p>[Authorized Signatory]</p>

      <p>Licensee:</p>
      <p>[Licensee's Name]</p>
      <p>[Authorized Signatory or Title]</p>

      <p>Date: [Date of Agreement Acceptance]</p>
    </div>
  );
};

export default LicensingAgreement;
