export default function Corporate(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>Corporate foundations have catalyzed global good for generations and ChangeFinder is committed to making your corporate giving strategy work for you that creates  intentional, measurable impact lasting for years to come in the communities you work in. Whether you have an established corporate social responsibility strategy or are just developing your strategy for corporate giving, ChangeFinder will work for you to advance the causes you care about in the markets you and your employees work in. Our simple to use corporate philanthropy software streamlines the giving, management, and reporting process to ensure that your grants, scholarships, employee giving, and corporate social responsibility strategies make the largest possible impact. </p>
                <p>Our easy to understand and use software is easily customized to fit your needs and make giving simple for you to create funding applications, manage submissions, sharing submissions with your team and get effective, timely reports. Our platform can help you engage employees and maximize their participation in your existing giving, matching and volunteering initiatives, or help you create new ones. We work with you to ensure that each part of your process works for you and is customized to fit the needs of your company,the programs that you fund, ultimately fulfilling your company's vision of corporate social responsibility. </p>
       
                </div>
            </div>
        </main>
    </div>
 );
}
