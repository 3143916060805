import React, { useState } from 'react';

function PaymentStatus() {
  const [email, setEmail] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  const checkPaymentStatus = async () => {
    try {
      const response = await fetch(`/api/check-payment?email=${email}`);
      const data = await response.json();
      setPaymentStatus(data.status);
    } catch (error) {
      console.error('Error checking payment status:', error);
    }
  };

  return (
    <div>
      <input
        type="email"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={checkPaymentStatus}>Check Payment Status</button>
      <p>Payment Status: {paymentStatus}</p>
    </div>
  );
}

export default PaymentStatus;
