import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import "../utils/firebase";
const auth = getAuth();
const pro=localStorage.getItem('proversion');
class MobileMenu extends Component {
    state = { 
        user: {},
        loggedin: false,
        pro: true,
        exp: "",
        isVisible: false
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {
        // Check if the mobile-menu is currently visible
        if (this.state.isVisible && this.menuRef.current && !this.menuRef.current.contains(event.target)) {
            this.setState({ isVisible: false });
        }
    };
    
        
    componentDidUpdate(prevProps) {
        if (this.props.user !== prevProps.user) {
            // Here, update the state based on user props.
            let currentDate = new Date();
            let futureDate = currentDate;
            if (this.props.user.data && this.props.user.data.exp) {
                futureDate = new Date(this.props.user.data.exp);
            }
            const isFutureDate = futureDate > currentDate;

            this.setState({
                user: this.props.user,
                loggedin: Boolean(this.props.user.uid),
                pro: isFutureDate,
                exp: futureDate
            });
        }
    }
    constructor(props) {
        super(props);
        // ... other code ...
    
        // Create a ref to the menu div
        this.menuRef = React.createRef();
    }

    toggleMenu = () => {
        this.setState({ isVisible: !this.state.isVisible });
    };
    toggleDropdown = () => {
        this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
    };

    render() {

        const renderUserLinks = () => {
            if (!this.props.user?.data) return null;

            if (this.state.pro) {
                return (
                    <>
                        {pro === "true"?(
                        <>
                        {/* <li className="py-10">
                                <a className="nav-link font-semibold text-5xl w-10 px-10" href="#/whoweserve/GrantChecker" onMouseDown={e => e.preventDefault()}>GrantChecker</a>
                            </li> */}
                            <li className="py-10">
                                    <a className="nav-link font-semibold text-5xl w-10 px-10" href="#/whoweserve/GrantMachine" onMouseDown={e => e.preventDefault()}>GrantMachine</a>
                                </li
                                
                                ></>
                        ): (
                            <li>
                            <a class="nav-link font-semibold text-5xl w-10 px-10" href="#/whoweserve/Subscribtionexpired" onMouseDown={e => e.preventDefault()}>Subscribtionexpired</a>
                            </li>
                        )}
                        
                    </>
                );
            } else {
                return (
                    <>
                        <li className="py-10">
                            <a className="nav-link font-semibold text-5xl px-10" href="#/whoweserve/Subscribtionexpired" onMouseDown={e => e.preventDefault()}>Subscription Expired</a>
                        </li>
                    </>
                );
            }
        };


        return (
            <>
           <div ref={this.menuRef} className={this.state.isVisible ? "mobile-menu flex w-full justify-end ml-auto" : "hidden mobile-menu"}>

                <ul className="flex space-x-4 ml-auto">
                    <li className="relative group">
                    <ul className={`${this.state.isVisible ? 'block' : 'hidden'} overflow-y-auto absolute top-0 right-0 mt-2 w-150 py-1 bg-white border border-gray-200 rounded shadow-lg z-10`}>
                                <li className="py-10">
                                    <a className="nav-link font-semibold text-5xl" href="#/whoweserve/features" onMouseDown={e => e.preventDefault()}>Features</a>
                                </li>
                                <li className="py-10">
                                    <a className="nav-link font-semibold text-5xl" href="#/payment" onMouseDown={e => e.preventDefault()}>Pricing</a>
                                </li>
                                <li className="py-10">
                                    <a className="nav-link font-semibold text-5xl" href="#/whoweserve/solution" onMouseDown={e => e.preventDefault()}>Solutions</a>
                                </li>
                                {renderUserLinks()}
                        {this.props.user?.data ? (
                            
                            <li className="py-10">
                                <a className="nav-link font-semibold text-5xl" href="#/" onMouseDown={e => e.preventDefault()} onClick={() => {auth.signOut(); this.setState({user: {}, loggedin: false})}}>Sign Out</a>
                            </li>
                        ) : (
                            <li className="py-10">
                                <a className="nav-link font-semibold text-5xl" href="#/login" onMouseDown={e => e.preventDefault()}>Login</a>
                            </li>
                        )}
                        </ul>
                    </li>
                </ul>
            </div>

            <button className="ml-auto mobile-menu-button navbar-btn" onClick={this.toggleMenu}>
                <span className="block w-10 h-2 rounded-full bg-primary"></span> {/* Increased width and height */}
                <span className="block w-10 h-2 mt-2 rounded-full bg-primary"></span> {/* Increased spacing */}
                <span className="block w-10 h-2 mt-2 rounded-full bg-primary"></span> {/* Increased spacing */}
            </button>

        </>
    );
}
}
const mapStateToProps = state => {
    return {
        user: state.user // Assuming you have user in your Redux state
    };
};

export default connect(mapStateToProps)(MobileMenu);
