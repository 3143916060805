import React from 'react';

// Define a single functional component
const PrivacyPolicy = () => {
  // Styles should be inside the component
  const headingStyle = {
    color: '#333',
    marginBottom: '20px',
  };

  const sectionStyle = {
    marginBottom: '30px',
  };

  const subsectionStyle = {
    marginLeft: '20px',
    listStyleType: 'none',
  };

  const listTitleStyle = {
    fontWeight: '500',
  };

  // Return your JSX content
  return (
    <div style={{ maxWidth: '800px', margin: '50px auto', fontFamily: 'Arial, sans-serif', backgroundColor: '#f4f4f4', padding: '35px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
    <h1 style={{ color: '#2c3e50', textAlign: 'center' }}>ChangeFinder Privacy Policy</h1>
    <p style={{ textAlign: 'right', color: '#666' }}>Effective Date: 15 Oct 2023</p>
    
    <p style={{ color: '#333', marginBottom: '20px' }}>
      At ChangeFinder, we are committed to safeguarding your privacy and ensuring the security of your personal data. This Privacy Policy outlines the collection, use, and protection of your information, including any AI-related aspects, when using our Software as a Service (SAAS) platform and associated services.
    </p>
    
    <h2 style={{ color: '#333', marginBottom: '20px' }}>1. Information We Collect</h2>
    <p>We collect various types of information to enhance our services and deliver personalized experiences to our users. This includes:</p>
    <ul style={{ marginLeft: '20px', listStyleType: 'none' }}>
      <li style={{ fontWeight: 'bold' }}>a. Personal Information</li>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Contact information</li>
      </ul>
      <li style={{ fontWeight: 'bold' }}>b. Usage Data</li>
      <ul>
        <li>Information about your interactions with our software, AI algorithms, and services</li>
      </ul>
    </ul>
    
    <h2 style={{ color: '#333', marginBottom: '20px' }}>2. Use of Information</h2>
    <p>We utilize the collected information for the following purposes, including AI-related functionalities:</p>
    <ul style={{ marginLeft: '20px' }}>
      <li>To comply with legal and regulatory requirements</li>
      <li>To optimize the user experience and personalize content</li>
      <li>To provide, maintain, and enhance our SAAS platform using AI algorithms</li>
      <li>To analyze usage patterns and improve our services using AI-driven insights</li>
    </ul>

     {/* ... Previous sections ... */}

<h2 style={{ color: '#333', marginBottom: '20px' }}>3. AI Algorithms and Data Analysis</h2>
<p>ChangeFinder utilizes AI algorithms to enhance the functionality and performance of our services. This includes data analysis, pattern recognition, and predictive modeling to improve our grant writing assistance and foundation application vetting capabilities.</p>

<h2 style={{ color: '#333', marginBottom: '20px' }}>4. Data Security</h2>
<p>We prioritize the security of your personal information. We implement appropriate measures to prevent unauthorized access, disclosure, alteration, or destruction of your data, including AI-related data.</p>

<h2 style={{ color: '#333', marginBottom: '20px' }}>5. Your Rights and Choices</h2>
<p>You have the right to:</p>
<ul style={{ marginLeft: '20px' }}>
<li>Access, correct, or update your personal information</li>
<li>Opt-out of certain data processing activities, including AI-based analytics</li>
<li>Request the deletion of your account and associated data</li>
</ul>

<h2 style={{ color: '#333', marginBottom: '20px' }}>6. Sharing of Information</h2>
<p>We may share your information, including AI-derived insights, with:</p>
<ul style={{ marginLeft: '20px' }}>
<li>Service providers and partners who assist in delivering our services</li>
<li>Legal and regulatory authorities as required by law or to protect our rights and interests</li>
<li>Third parties in the event of a business transaction, such as a merger or acquisition</li>
</ul>

<h2 style={{ color: '#333', marginBottom: '20px' }}>7. Cookies and Tracking Technologies</h2>
<p>We utilize cookies and similar technologies to enhance user experience, analyze usage patterns, and improve our AI algorithms. You can manage your cookie preferences through your browser settings.</p>

<h2 style={{ color: '#333', marginBottom: '20px' }}>8. Changes to this Privacy Policy</h2>
<p>We reserve the right to update this Privacy Policy to reflect changes in our practices and services. Any updates will be effective upon posting the revised policy on our website.</p>


    
    <h2 style={{ color: '#333', marginBottom: '20px' }}>9. Contact Us</h2>
    <p>
      If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at 
      <a href="mailto:admin@changefinder.app" style={{ color: '#3498db', textDecoration: 'none' }}> admin@changefinder.app</a>.
    </p>
  </div>
  );
};

export default PrivacyPolicy; // Export your component



