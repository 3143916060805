export default function LoginRequest(props) {
    return ( 
        <div className='page'>
            <main>
                <div className="page__box">
                    <div className="page__page">
                    <p>Thank You for login.</p>
    
                    <p>Your details has been sent to admin. </p>
    
                    </div>
                </div>
            </main>
        </div>
     )}