import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase"
import { call, put, takeLatest } from 'redux-saga/effects';

async function getUserData(user) {
  const dbRef = collection(db, "users");
  const q = query(dbRef, where("email", "==", user.email));
  
  let querySnapshot = await getDocs(q);
  
  user.data = querySnapshot.docs[0].data();

  return user;
}

function* updateUser(action) {
  try {

    let user = action.payload

    user = yield call(getUserData, user)

    console.log('UPDATE_USER:', user, "at", window.location.hash)

    yield put({ type: 'SET_USER', payload: user });
    
    if ("data" in Object.keys(user) && window.location.hash === "#/login") { 
      window.location.replace('#/user/profile');
    }
  } catch (error) {
    console.log('Error in updateUser', error);
  }
}

function* userSaga() {
  yield takeLatest('UPDATE_USER', updateUser);
}

export default userSaga;