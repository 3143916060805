export default function Donors(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>Individual donors help nonprofits move mountains. Gifts from donors creates impact by helping nonprofits deliver on their projects and programs. ChangeFinder helps individual donors accelerate their giving by matchmaking you with causes that matter to you the most and suggesting organizations, initiatives and projects that align with your existing giving priorities and interests.</p>

                <p>Whether you have been funding organizations and initiatives for decades, or are just getting started, ChangeFinder’s donor software will make your giving impact larger and connect you with the causes you care about most. </p>

                </div>
            </div>
        </main>
    </div>
 );
}