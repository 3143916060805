export default function Careers(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>We are always looking for mission-driven team members who love to have fun while they are making a difference. </p>
                </div>
            </div>
        </main>
    </div>
 );
}