import React, { useEffect, useState } from 'react'
import { getAuth } from "firebase/auth";
import { db } from "../../utils/firebase";

const Success = () => {
  const [userId, setUserId] = useState("");
  const [sessionId, setSessionId] = useState("");

  useEffect(()=>{
    getAuth().onAuthStateChanged((user)=> {
      if(user){
        setUserId(user.uid)
        const userRef = db.ref("users/" + user.uid);
        userRef.on('value', (snapshot) => {
          const user = snapshot.val();
          if(user){
            setSessionId(user.subscription.sessionId || "")
          }
          handlePaymentSuccess();
        })
      }
    })

  }, [userId, sessionId]);

  console.log(sessionId)

  const handlePaymentSuccess = () => {
    fetch("http://localhost:5000/api/v1/payment-success", {
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify({sessionId: sessionId, firebaseId: userId})
    })
    .then(res => {
      if(res.ok) return res.json();
      return res.json().then(json => Promise.reject(json));
      window.location.replace('#/whoweserve/GrantMachine');
    })
    .then(data => {
      console.log(data.message);
      window.location.replace('#/whoweserve/GrantMachine');
    })
    .catch(e => {
      console.log(e.error);
      window.location.replace('#/whoweserve/GrantMachine');
    });
  }


  return (
    <div className='m-0 p-0'>
      <div className='w-full min-h-[80vh] flex flex-col justify-center items-center'>
        <div className='my-10 text-green-600 text-2xl mx-auto flex flex-col justify-center items-center'>
          <h3 className='text-4xl pt-20 lg:pt-0 font-bold text-center text-slate-700'>
           Processing Payment....
          </h3>
          <button onClick={() => handlePaymentSuccess()}
          className='w-40 uppercase bg-[#009C96] text-white text-xl my-16 px-2 py-2 rounded'
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}

export default Success