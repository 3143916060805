export default function Jobseekers(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>Nonprofit contractors and employees do amazing work to advance the projects and programs of mission-driven organizations. Often nonprofits have to downsize operations or can’t renew the contracts of workers who do amazing work. Through ChangeFinder, we connect profiles of impactful nonprofit professionals to employment opportunities to help advance the missions of organizations that are changing the world based on their skills and experience.</p>

                <p>Whether you have been helping organizations raise funding and manage programs for decades, or are just getting started, ChangeFinder’s job matchmaking software will connect you with the best opportunities available to increase your ability to serve value driven organizations that are focused on impact. </p>

                </div>
            </div>
        </main>
    </div>
 )}