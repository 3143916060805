import { loadStripe } from '@stripe/stripe-js';
import React, { Component } from 'react';

const PUBLIC_KEY = "pk_test_51NcyscCJOhsa9cHzf1psSpKYg7KSzPOyHv7YkJ81smNjzMfhEMUVzDHKcjQrs9PdXHgG6DfHMhZamDPPq7tSha5500ajecUCpw"


class Payment extends Component {
    constructor() {
        super();
        this.state = {
            isMonthly: true,
        };
    }
    redirectToAnotherPaymentMonthSubscription = () => {
        window.location.href = '#/bills80';
    }
    redirectToAnotherPaymentMonth = () => {
        window.location.href = '#/bills80';
    }

    redirectToAnotherPayment = () => {
        window.location.href = '#/bills800';
    }

    redirectToLogin = () => {
        window.location.href = 'https://www.changefinder.app/#/login';
    }

    togglePricing(mode) {
        this.setState({ isMonthly: mode === "monthly" });
    }

    PricingCard(props) {
        return (
            <div className="card-header bg-primary">
                <div className="card-header bg-transparent pb-0">
                    <h3 className="font-semibold display-6 mb-0">{props.title}</h3>
                </div>
                <div className="card-footer">
                    <h2 className="display-5 font-semibold mb-3">
                        <span>{props.price}</span>
                        <span className="text-3xl">{props.period}</span>
                    </h2>
                    <p className="text-muted text-3xl">{props.description}</p>
                    <ul className="fa-ul ml-6 leading-8">

                    </ul>
                </div>
                <div className="card-footer">
                <button style={{ backgroundColor: '#48BB78' }} className="btn btn-ghost block w-full btn-lg text-4xl" onClick={props.onClick}>
                        Get Started Now
                    </button>
                </div>
            </div>
        );
    }

    render() {
        const monthlyPrice = this.state.isMonthly ? "$80" : "$800";
        const period = this.state.isMonthly ? "/mo" : "/yr";
        const redirectFunction = this.state.isMonthly ? this.redirectToAnotherPaymentMonth : this.redirectToAnotherPayment;
        const pricingText = this.state.isMonthly 
            ? "No additional costs. Pay a fixed monthly fee we handle everything for you." 
            : "No additional costs. Pay a fixed yearly fee. We handle everything for you.";

        return (
            <section className="py-28" id="pricing" aria-label="Pricing Section">
                <div className="container">
                    <div className="row">
                        <div className="col mx-auto text-center mb-6">
                            <h2 className="display-6 font-semibold mb-3">Our Pricing</h2>
                            <p className="text-4xl">{pricingText}</p>
                        </div>
                        <div className="col flex justify-center">
                            <div className="btn-group">
                                <button onClick={() => this.togglePricing("monthly")} className="btn btn-primary rounded-none" id="monthly-pricing-button" aria-label="Monthly Pricing">Monthly</button>
                                <button onClick={() => this.togglePricing("annual")} className="btn btn-outline btn-primary rounded-none" id="annual-pricing-button" aria-label="Annual Pricing">Annual</button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-12 justify-center gap-y-8 space-x-0">
                        <this.PricingCard 
                            title="Pro"
                            price={monthlyPrice}
                            period={period}
                            description={this.state.isMonthly 
                                        ? "Elevate your grantwriting efforts with our monthly subscription plan priced at just $80 per month with autopay and get 1 months free. Unlock a comprehensive suite of features to revolutionize your grantwriting approach. This subscription makes it an ideal choice for small grantwriting teams of three or less. Maximize your ability to secure grants effectively and take your grantwriting endeavors to the next level."
                                        : "Commit to grantwriting success with ChangeFinder's annual subscription, priced at just $800 per year with autopay and get 1 months free. Designed for larger grantwriting teams or organizations, this tier accommodates up to ten users, making it ideal for collaborative efforts. With the annual subscription, your entire team can join forces and embark on a journey toward grantwriting success."}
                            onClick={redirectFunction}
                        />
                    </div>
                </div>
                <br/><br/>
            </section>
        );
    }
}

export default Payment;
