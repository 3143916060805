export default function Foundations(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>We have been fortunate to work with, and for, Foundations for 10+ years to advance solutions to some of the world’s most pressing challenges. We are committed to advancing the causes founders and boards are passionate about. </p>

                <p>Whether you have been funding organizations and solutions for generations, or are just getting started, ChangeFinder’s grant management software will make your giving processes more efficient. Always saving your organization time and money, while increasing your impact. </p>

                <p>We match you to the most relevant nonprofits for your giving opportunities so that you can focus on advancing your vision with the organizations that are most capable of making a difference and who best match your selection criteria. Our easy to understand and use software is easily customized to fit your needs and makes it simple for you to create grant applications, manage grant submissions, share submissions with reviewers and board members, and get effective reports. We work with you to ensure that each aspect of your grant process is optimized and customized to fit the needs of your foundation and the nonprofits that you fund.</p>

                </div>
            </div>
        </main>
    </div>
 )}