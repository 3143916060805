import {React, Component} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import {
  faThumbsUp,
  faThumbsDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Spacer from "../../tools/Spacer";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import './GrantScoringRubric.css'; // Assuming you've put the CSS in a separate file


const openApiKey="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";


const generatePrompt = (question) => {
  return `\n
Grant: ${question} \n
Total:`;};




const questions = [
  {
    id: 0,
    question:
      "",
    response: "",
  }
];

export default class UnitedWayGrantReviewer extends Component {
    getHighlightClass(criteria, feedbackArray, index) {
            criteria.score = feedbackArray[index]

        if (criteria.maxScore == 10)
        {
            var score = criteria.score;
            if (score >= 7) {
                return 'bg-success'; // This class should define a background color indicating a high score.
              } else if (score >= 4) {
                return 'bg-warning'; // This class should define a background color indicating a medium score.
              } else {
                return 'bg-danger'; // This class should define a background color indicating a low score.
              }
        }
        if (criteria.maxScore == 6)
        {
            var score = criteria.score;
            if (score >= 5) {
                return 'bg-success'; // This class should define a background color indicating a high score.
              } else if (score >= 4) {
                return 'bg-warning'; // This class should define a background color indicating a medium score.
              } else {
                return 'bg-danger'; // This class should define a background color indicating a low score.
              }
        }

        if (criteria.maxScore == 5)
        {
            var score = criteria.score;
            if (score >= 4) {
                return 'bg-success'; // This class should define a background color indicating a high score.
              } else if (score >= 3) {
                return 'bg-warning'; // This class should define a background color indicating a medium score.
              } else {
                return 'bg-danger'; // This class should define a background color indicating a low score.
              }
        }
    
      }


      
  constructor() {
    super();
    this.state = {
      prompt: { },
      customQuestion: false,
      promptQuestions: questions,
      rated: false,
      rating: "",
      score: "",
      feedback: "",
      loading: false,
      userRating: null,
      showForm: false,
      titleGrant:"",
      selectedOption:"",
      feedbackMessage: "",
      

    };
    this.togglePrompt = this.togglePrompt.bind(this);
    this.nextPressed = this.nextPressed.bind(this);
    this.setShowForm = this.setShowForm.bind(this);
    this.ratePressed = this.ratePressed.bind(this);
    this.submitPressed = this.submitPressed.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResponseChange = this.handleResponseChange.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    //window.location.reload();
  }



  async getRating4(Question) {
    const openai = axios.create({
        baseURL: "https://api.openai.com/v1",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer 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`,
        },
      });
  



    this.setState({ loading: true });
    let t =  generatePrompt(Question);
    //alert(t);
   

    let System1 = `
    you are a proffessional grant reviewer, with strict standards, only accept well written grants, otherwise give low grades.
    
    write some high level feedback, and also return ONLY an array in the format.
     [ 
        x,  // Score for Program Description
        x,  // Score for United Way Criteria
        x,  // Score for Target Population
        x,  // Score for Innovation and Duplication of Services
        x,  // Score for Budget
        x,  // Score for Detailed Budget Narrative
        x,  // Score for Program Core Services
        x,  // Score for Program Activities
        x,  // Score for Outcome Measures
        x,  // Score for Data Collection
        x,  // Score for Partnerships & Collaborations
        x,  // Score for Workplace Campaigns
        x,  // Score for Organization's Financial Document Submission

    ];
    where "x" gets replaced with the relevent scores according to this rubric below.
    ONLY use numbers in the array, instead of NA return 0.
    Remember never to return a number higher than what the score is out of.
Rubric: 
Program Description: Is the program goal or purpose clearly identified? (Out of 10)
United Way Criteria: Does the program clearly align with United Way program criteria? (Out of 10)
Target Population: Is the target population clearly identified? (Out of 10)
Innovation and Duplication of Services: Is there a clear description on programs providing non-duplicated services? (Out of 10)
Workplace Campaigns: If applicable, all partners agree to run a workplace campaign, which should include capturing participation, setting a goal and running a campaign. (Out of 5)
Organization's Financial Document Submission: if applicable, all financial documentation should be turned in complete and on time. (Out of 5)
Program Core Services: Are they well thought out and clearly explained? (Out of 6)
Program Activities: Are changes within the program participants clearly identified through program activities/services? (Out of 6)
Partnerships & Collaborations: Are program partnerships and collaborations clearly defined and used to enhance program delivery? (Out of 5)
Outcome Measures: Are the outcomes measurable actions, realistic and timely? Are they achievable? (Out of 6)
Data Collection: Is there a data collection plan in place to be used for future program improvements? (Out of 6)
Budget: Is the budget detailed, complete, and clearly supports the work outlined? (Out of 10)
Detailed Budget Narrative: Is use/purpose of funding detailed and clear (Out of 10)
Story provided that aligns (Yes or no)`

    //this is what you are going to work on with ian and madiline to get it to be a lotttt better, remember, you have 3 defined roles, System, user, and assistant. 
    const messages = [
      {role: "system", content: System1},
      { role: "user", content: t },
    ];
  
    // ...

    const options = {
      temperature: 0,
      max_tokens: 2300,
      //should help make more deterministic.
     // seed: 42, 
    };


    const maxRetries = 5;
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const response = await openai.post("/chat/completions", {
          model: "gpt-4",
          messages,
          ...options,
        });
  
        const string1 = response.data.choices[0].message.content;
        const arrayStart = string1.indexOf('[');
        const myMess = string1.slice(0, arrayStart).trim();
        const fullString = string1.slice(arrayStart);
  
        const auth = getAuth();
        const user = auth.currentUser;
        const email = '';
  
        if (user !== null) {
          const email = user.email;
          console.log(email);
        }
  
        this.setState({
          feedback: fullString,
          feedbackMessage: myMess,
          loading: false,
          rated: true,
          showForm: false,
        });
        
        // Break out of the loop if request is successful
        break;
      } catch (error) {
        console.error(`Error creating chat completion on attempt ${attempt + 1}:`, error);
        
        // If it's the last attempt, throw the error
        if (attempt === maxRetries - 1) {
          this.setState({ loading: false });  // Update state to stop loading
          throw error;
        }
  
        // Optionally, you can add a delay before retrying, e.g. 2 seconds
        await new Promise(res => setTimeout(res, 2000));
      }
    }
  


 
  
    }



  setUserFeedback(verb) {
    this.setState({ userRating: verb });
  }
  ratePressed() {
    var { promptQuestions, prompt } = this.state;
    console.log("ratePressed");
    this.getRating4(prompt.question);
    promptQuestions = prompt;
    this.setState({ promptQuestions, customQuestion: false, rated: true });
  }

  setShowForm(event){
    this.setState({showForm: true });
  }

  submitPressed() {
    const { promptQuestions, prompt } = this.state;
    this.getRating4( prompt.question,"1");
    
    promptQuestions[prompt.id] = prompt;
    this.setState({ promptQuestions, customQuestion: false, rated: true });
  }

  nextPressed() {
    const { promptQuestions, prompt } = this.state;
    const newPrompt = promptQuestions[prompt.id + 1];
    this.setState({
      prompt: newPrompt,
      customQuestion: false,
      rated: false,
    });
  }

  togglePrompt(bool) {
    const { prompt, promptQuestions } = this.state;
    if (bool) {
      promptQuestions[prompt.id].response = prompt.response;
    }
    this.setState({
      prompt: bool
        ? { id: promptQuestions.length, question: "", response: "" }
        : promptQuestions[promptQuestions.length - 1],
      customQuestion: bool,
      promptQuestions,
    });
  }
  renderQuestion() {
    const { prompt } = this.state;
    return <p className="h2 my-4 bold">{prompt.question}</p>;
  }

  renderTextInput() {
    const { prompt } = this.state;
    return (

<textarea
  id="customPrompt"
  rows="10"
  cols="60"
  name={prompt.id}
  className="w-full h-60 p-4 my-4 border rounded resize-none"
  placeholder="Give your own question"
  value={prompt.question}
  onChange={this.handleInputChange}
/>

    );
  }

  handleInputChange(event) {
    const { value } = event.target;
    const { prompt } = this.state;
    prompt.question = value;
    this.setState({
      prompt,
    });

  }
  handleSelectChange(event){
    console.log("option is "+event.target.value);

    this.setState({
      selectedOption:event.target.value,
    });
  }

  handleTitleChange(event){
    var { value } = event.target.value;
    console.log("option is "+event.target.value)
    this.setState({
      titleGrant:value,
    });
  }
  
  handleResponseChange(event) {
    const { value } = event.target;
    const { prompt } = this.state;
    prompt.response = value;
    this.setState({
      prompt,
    });
    //if(value.length%20===0){
    //this.ratePressed();
  //}
  }
//for copying text

  

render() {
  let {
    customQuestion,
    prompt,
    rated,
    promptQuestions,
    rating,
    shortfeedback,
    score,
    feedback,
    feedbackMessage,
    loading,
    userRating,
    showForm,
    selectedOption,
    titleGrant,
    
  } = this.state;
  const criteriaList = [
    { name: 'Program Description: Is the program goal or purpose clearly identified?', score: 10 , maxScore: 10},
    { name: 'United Way Criteria: Does the program clearly align with United Way program criteria?', score: 8, maxScore: 10},
    { name: 'Target Population: Is the target population clearly identified?', score: 6 , maxScore: 10},
    { name: 'Innovation and Duplication of Services: Is there a clear description on the program providing non-duplicated services?', score: 0, maxScore: 10},
    { name: 'Budget: Is the budget detailed, complete, and clearly supports the work outlined?', score: 9 , maxScore: 10},
    { name: 'Detailed Budget Narrative: Is use/purpose of funding detailed and clear?', score: 9, maxScore: 10},
    { name: 'Program Core Services: Are they well thought out and clearly explained?', score: 5 , maxScore: 6},
    { name: 'Program Activities: Are changes within the program participants clearly identified through program activities/services?', score: 2, maxScore: 6},
    { name: 'Outcome Measures: Are the outcomes measurable actions, realistic and timely? Are they achievable?', score: 3 , maxScore: 6},
    { name: 'Data Collection: Is there a data collection plan in place to be used for future program improvements?', score: 1, maxScore: 6},
    { name: 'Partnerships & Collaborations: Are program partnerships and collaborations clearly defined and used to enhance program delivery?', score: 4, maxScore: 5},  
    { name: 'Workplace Campaigns: If applicable, all partners agree to run a workplace campaign, which should include capturing participation, setting a goal and running a campaign', score: 1, maxScore: 5},
    { name: 'Organization\'s Financial Document Submission: if applicable, all financial documentation should be turned in complete and on time.', score: 5, maxScore: 5},
   
    //... other criteria
  ];

return (
  <div className="flex flex-col items-center justify-center p-8 bg-gray-100 rounded-lg shadow-md w-full max-w-screen-lg mx-auto">
  <h1 className="text-4xl font-bold my-5">United Way Grant Reviwer</h1>
  <h3 className="text-lg my-5 text-gray-600">
    Paste in a grant, and we'll evaluate it according to United Way's Rubric
  </h3>

    <textarea
      rows="10"
      className="w-full h-60 p-4 my-4 border rounded resize-none"
      placeholder="Place your grant here!"
      value={prompt.question}
      onChange={this.handleInputChange}
    />


<div className="flex justify-between w-full mt-4">
  <button
    className="px-8 py-2 text-white bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
    onClick={this.ratePressed}
  >
    Click here for feedback
  </button>
</div>

    {loading ? (
          <>
          <div>
            <div className="my-5">
            <div className="border"></div>
            <Spacer height={"50px"} />
            <img src="https://shortpixel.com/img/spinner2.gif" width="128" height="128"/>

            <Spinner animation="border" />
            </div>
            </div>
          </>
    ) : null}

    {rated && !loading && (
        
      <div className="w-full mt-5 p-4 bg-white rounded-lg shadow-md">
        
        <div className="d-flex justify-content-between align-items-center border-b">
   
     
     
          <div className="m-2">   
            <button className="btn btn-primary me-2" onClick={() => {navigator.clipboard.writeText(feedback)}}>Copy</button>
           
          </div>
        </div>
        <div className="header-container">
        <img width="150" height="150" alt="company logo" src="https://1000logos.net/wp-content/uploads/2018/04/symbol-United-Way.jpg" />
        <h2>United Way 2020-2022 Community Impact Grant Scoring Rubric</h2>
        </div>
        <div id="totalScore" className="large-feedback-text">Feedback: {feedbackMessage}</div>

        <div className="container my-4">
        <table className="tableone">
        <thead>
          <tr>
            <th>Criteria</th>
            <th>Score</th>
       
          </tr>
        </thead>
        <tbody>
          {criteriaList.map((criteria, index) => {
            let feedbackArray = [];
             try {
            feedbackArray = JSON.parse(feedback);
             }
             catch (error)
             {
              feedbackArray = [4, 2, 10, 5, 6, 3, 3, 6, 2, 1, 5, 2, 3 ]
             }
            const highlightClass = this.getHighlightClass(criteria, feedbackArray, index);
            return (
              <tr key={index} className={highlightClass}>
                <td>{criteria.name}</td>
                <td>{criteria.score}/{criteria.maxScore}</td>
              
              </tr>
            );
          })}
        </tbody>
      </table>
        </div>
       

        <div className="text-end m-2">
          <button className={`btn btn-success rounded-circle ${userRating === "LIKE" ? "bg-green-500" : "bg-green-300"}`} onClick={() => {this.setUserFeedback("LIKE")}}>
            👍
          </button>
          <button className={`btn btn-danger rounded-circle ${userRating === "DISLIKE" ? "bg-red-500" : "bg-red-300"}`} onClick={() => {this.setUserFeedback("DISLIKE")}}>
            👎
          </button>
        </div>
      </div>
    )}
  </div>
);
  }
}

