import { Outlet} from "react-router-dom";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { connect } from 'react-redux';

function Layout(props) {
    return (
        <div className="layout">
            <Navbar active={props.navbar} key={props.user?.uid} />
            <Outlet />
        </div>
    )
}

const mapStateToProps = (props) => (props);
export default connect(mapStateToProps)(Layout);