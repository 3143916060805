import { connect } from 'react-redux';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase";
import React, { Component } from 'react';

class Grants extends Component {
    state = { 
        loaded: false,
        grants: [],
        activeGrant: 0
    }

    getGrants = async () => {
        const querySnapshot = await getDocs(collection(db, "grants"));
        console.log(querySnapshot.docs);
        const grantList = []
        querySnapshot.forEach((doc) => {
            let data = doc.data()
            data.id = doc.id;
            data.matchPercent = Math.round(Math.random() * 100);
            grantList.push(data)
        });

        const objectComparisonCallback = (a, b) => {
            if (a.matchPercent < b.matchPercent) {
              return 1
            }
          
            if (a.matchPercent > b.matchPercent) {
              return -1
            }
          
            return 0
        }

        grantList.sort(objectComparisonCallback)

        this.setState({
            loaded: true,
            grants: grantList
        })
    }
    
    componentDidMount() {
        console.log("loading")
        if (!this.state.loaded) {
            this.getGrants()
        }
    }
    
    render() { 
        let activeGrant = this.state.grants[this.state.activeGrant]
        if (this.state.loaded) {
            return (
                <div className="grants">
                    <h1>Apply for Grants</h1>
                    <main className='grants__list'>
                        <div className='grants__sidebar'>
                            {this.state.grants.map((grant, index) => {
                                return (
                                    <div className={`grant ${this.state.activeGrant === index && "grant--active"}`} key={grant.id} onClick={() => this.setState({activeGrant: index})}>
                                        <div className='grant__header'>
                                            <h2 className='grant__title'>{grant.name}</h2>
                                            <p className='grant__amount'>${grant.amount}</p>
                                        </div>
                                        <p className='grant__description'>{grant.description}</p>
                                        <div className='grant__hearts'>
                                            <i className="fas fa-heart"></i>
                                            {grant.matchPercent > 50 && <i className="fas fa-heart"></i>}
                                            {grant.matchPercent > 75 && <i className="fas fa-heart"></i>}
                                            {grant.matchPercent > 90 && <i className="fas fa-heart"></i>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='grants__body'>
                            <div className='grants__header'>
                                <h1 className='grants__title'>{activeGrant.name}</h1>
                                <p className='grants__amount'>${activeGrant.amount}</p>
                            </div>
                            <p className='grants__description'>{activeGrant.description}</p>
                            <iframe title={activeGrant.link} src={activeGrant.link} width="100%"></iframe>
                            <p>If the link doesn't load, please click <a href={activeGrant.link} target="none">here</a> to apply directly on their site.</p>
                        </div>
                    </main>
                </div>
            )
        } else return <div height="calc(100vh - 8rem)"></div>
    }
}

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(Grants);