import Logo from "../img/LogoWhite.PNG";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "../store";
import "../utils/firebase";

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log("UID:", uid);

    store.dispatch({ type: "UPDATE_USER", payload: user })
    // ...
  } else {
    // User is signed out
    store.dispatch({ type: "UNSET_USER" })
    // ...
  }
});

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(function NavbarNonprofit({user}) {
    return(
        <nav className="navbar navbar--active">
            <div className="navbar__left">
                <Link to="/" onMouseDown={e => e.preventDefault()} >
                    <img className="navbar__logo" src={Logo} alt="Changefinder Logo"/>
                </Link>
            </div>
            <ul className="navbar__links">
                <li className="navbar__link"><Link to="/" onMouseDown={e => e.preventDefault()} >Home</Link></li>
                <li className="navbar__link"><Link to="/np/grants" onMouseDown={e => e.preventDefault()} >Grants</Link></li>
                <li className="navbar__link"><Link to="/np/analytics" onMouseDown={e => e.preventDefault()} >Analytics</Link></li>
                <li className="navbar__link"><Link to="/user/profile" onMouseDown={e => e.preventDefault()} >Profile</Link></li>
                <li className="navbar__link"><Link to="/" onMouseDown={e => e.preventDefault()} onClick={() => auth.signOut()} >Logout</Link></li>
            </ul>
        </nav>
    )
})