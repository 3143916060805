import logo from '../../img/LogoSymbolWhite.png';
import React, {Component,useState} from 'react';
import Card from '../../components/card';

const exp=localStorage.getItem('exp');

class Home extends Component {
    redirectToAnotherPaymentMonth = () => {
        // Redirect to the desired link
        window.location.href = 'https://buy.stripe.com/test_00g02cd5ogtrfHW000';
      }
    redirectToAnotherPayment = () => {
        // Redirect to the desired link
        window.location.href = 'https://buy.stripe.com/test_4gwaGQ0iCb97gM0fYZ';
      }
    state = {
        background: 0,
        month:true
    }

    

    componentDidMount() {
        this.props.setNavbar(false);

        window.addEventListener("scroll", this.changeNavbar);

        this.isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

        if (!this.isReduced) {
            this.timer = setInterval(() => {
                if (this.state.background < 3) {
                    this.setState({
                        background: this.state.background
                    });
                } else {
                    this.setState({
                        background: 0
                    });
                }
            }, 7500);
        }
        this.startRotation();
    }
    
    componentWillUnmount() {
        clearInterval(this.timer);
    }
    
    changeNavbar = () => {
        if (window.scrollY > 10) {
            if (!this.props.navbar) {
                this.props.setNavbar(true)
            }
            return; 
        }

        if (this.props.navbar) {
            this.props.setNavbar(false)
        }
    }

    setBackground = (i) => {
        this.setState({
            background: 1
        })

        clearInterval(this.timer);

        this.isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`) === true || window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

        if (!this.isReduced) {
            this.timer = setInterval(() => {
                if (this.state.background < 3) {
                    this.setState({
                        background: this.state.background + 1
                    });
                } else {
                    this.setState({
                        background: 0
                    });
                }
            }, 7500);
        }
    }
    state = {
        background: 0,
        month: true,
        activeSlide: 'testimonial1'  // This replaces useState
    }


    componentWillUnmount() {
        clearInterval(this.timer); // Clear any timers
        clearInterval(this.rotationTimer); // Clear the rotation timer
    }

    // Function to start the rotation
    startRotation = () => {
        this.rotationTimer = setInterval(this.rotateSlide, 7500); // Rotate every 7500ms or 7.5 seconds
    }

    // Function to handle rotation
    rotateSlide = () => {
        if (this.state.activeSlide === 'testimonial1') {
            this.setActiveSlide('testimonial2');
        } else {
            this.setActiveSlide('testimonial1');
        }
    }

    setActiveSlide = (slide) => {
        this.setState({
            activeSlide: slide
        });
    }

  
    render(
    ) {
        //const [activeSlide, setActiveSlide] = useState('testimonial1');
        return (

            <><section class="pt-10 pb-28 bg-primary-50 overflow-hidden" id="header">
                    <div class="container text-center lg:text-left">
                        <div class="row items-center">
                            <div class="col md:w-8/12 lg:w-4/12 mx-auto">
                            <h1 class="display-6 font-semibold mb-50 text-7xl">ChangeFinder</h1>
                                <p class="mb-40 text-5xl">Our software makes it fast and easy to write grants.</p>
                                <a href="#/login" class="inline-block px-6 py-4 bg-[#549C30] hover:bg-[#1d4728] text-6xl font-bold text-white rounded-xl">
  Start FREE trial ➔
</a>
                            </div>
                            <div class="col md:w-10/12 lg:w-8/12 pt-12 lg:pt-0 mx-auto relative">
                            <div class="absolute rounded-full -mt-12 w-[300px] h-[300px] sm:w-[500px] sm:h-[500px] md:w-[600px] md:h-[600px] bg-gradient-to-br from-primary-300 via-primary-100 to-primary-100 left-[40%] sm:left-[50%] md:left-[40%]"></div>
                              {/* REPLACE SOON, DISCORDS FILESYSTEM IS FUCKING SWITCHING UP AT 2024 */}
            <img alt="product" class="relative" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/38d98890-d895-4430-8cac-dbe9274369d7.png" />
        </div>

                        </div>
                    </div>
                </section>
                <div class="col text-light">
                    <div class="mb-10 h-0.5 bg-primary"></div>
                </div>
                <section class="py-12 bg-primary-50" id="clients">
                    <div class="container mx-auto">
                        <div class="text-center mb-8">
                            <h2 class="font-semibold mb-4">Trusted by organizations everywhere</h2>
                            <p class="w-9/12 mx-auto text-4xl">Our software is trusted by nonprofits, public sector agencies and startups around the world.</p>
                        </div>
                        <div class="flex justify-center items-center space-x-4 mb-4">
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/a29b99ed-1b0d-4a18-9ae7-8c64094a96cf.png" class="max-h-[48px] w-auto max-w-full" alt="logo1" />
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/ec191a55-e2a5-4733-8230-517135d5ada7.png" class="max-h-[48px] w-auto max-w-full" alt="logo2" />
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/9ae9c468-f4aa-4c66-b519-25fb3b22ea82.png" class="max-h-[48px] w-auto max-w-full" alt="logo3" />
                        </div>
                        <div class="flex justify-center items-center space-x-4">
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/1fc45e04-2663-42a4-a303-21215970c2bb.png" class="max-h-[48px] w-auto max-w-full" alt="logo4" />
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/265612ea-d5af-4e5a-bbc0-a369ca06e065.png" class="max-h-[48px] w-auto max-w-full" alt="logo5" />
                            <img src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/41a8b5dc-190f-4e31-9429-a11f6ff39ff7.png" class="max-h-[48px] w-auto max-w-full" alt="logo6" />
                        </div>
                    </div>                            
                </section>
    <div class="col text-light">
        <div class="mb-10 h-0.5 bg-primary"></div>
    </div>

    <div className="container mx-auto text-center">
  <div className="w-3/4 mx-auto h-max-[400px] rounded-md mb-10">
    {/* Navigation buttons for the carousel */}
    {/* Testimonial 1 */}
    <section
      className={`carousel-item py-12 mx-auto ${
        this.state.activeSlide === "testimonial1" ? "block" : "hidden"
      }`}
      id="testimonial1"
    >
      <section class="py-10" id="testimonials">
        <div class="container text-center lg:text-left">
          <div class="row items-center">
            <div class="bg-white shadow-lg p-6 rounded-lg">
              <div class="col md:w-10/12 lg:w-7/12 order-1 lg:order-none mx-auto">
                <img
                  alt="product"
                  loading="lazy"
                  class="block mx-auto max-w-[250px] max-h-[200px] active:-mt-16 2xl:active:-mb-28"
                  src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/c0631816-1cbd-49dc-8ef8-16c9dab1532e.jpeg"
                />
              </div>
              <div class="col md:w-8/12 lg:w-5/12 mx-auto order-none lg:order-12 mb-12 lg:mb-0 py-3">
                <h2 class="font-semibold mb-6 text-xl lg:text-xl">
                  "I’ve been using GrantMachine to assist with questions from my last grant submission, and it has proven to be quite helpful. I truly appreciate it!”
                </h2>
                <div class="flex flex-col lg:flex-row mb-6">
                  <div>
                    <img
                      alt="testimonial face"
                      src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/a29b99ed-1b0d-4a18-9ae7-8c64094a96cf.png"
                      class="w-10 h-10 mx-auto mb-4 rounded-full"
                    />
                  </div>
                  <div class="ml-6">
                    <p class="mb-0 font-semibold">Sadie Green</p>
                    <p class="text-muted">Endowment & Grants, Himalayan Children’s Charities</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    {/* Testimonial 2 */}
    <section
      className={`carousel-item py-8 mx-auto ${
        this.state.activeSlide === "testimonial2" ? "block" : "hidden"
      }`}
      id="testimonial2"
    >
      {/* ... (Existing content for testimonial2) */}

      <section class="py-10" id="testimonials">
        <div class="container text-center lg:text-left">
          <div class="row items-center">
            <div class="bg-white shadow-lg p-6 rounded-lg">
              <div class="col md:w-10/12 lg:w-7/12 order-1 lg:order-none mx-auto">
                <img
                  alt="product"
                  loading="lazy"
                  class="block mx-auto max-w-[250px] max-h-[200px] active:-mt-16 2xl:active:-mb-28"
                  src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/5164b3ea-5b1b-473c-9af1-7d28a378ac37.jpeg"
                />
              </div>
              <div class="col md:w-8/12 lg:w-5/12 mx-auto order-none lg:order-12 mb-12 lg:mb-0 py-3">
                <h2 class="font-semibold mb-6 text-xl lg:text-xl">
                  "We use GrantMachine for our grant submissions to potential and established funders. Love how easy it is to use and how much it speeds up my grant writing.”
                </h2>
                <div class="flex flex-col lg:flex-row mb-6">
                  <div>
                    <img
                      alt="testimonial face"
                      src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/ec191a55-e2a5-4733-8230-517135d5ada7.png"
                      class="w-10 h-10 mx-auto mb-4 rounded-full"
                    />
                  </div>
                  <div class="ml-6">
                    <p class="mb-0 font-semibold">Saoirse Scott</p>
                    <p class="text-muted">MARKET DIRECTOR, WHITEAKER COMMUNITY MARKET</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</div>

            
            </>   
            
            
        )
    }
}

export default Home;
