export default function Subscribtionexpired(props) {

    return ( 
<section class="py-28" id="testimonials">
    <div class="container text-center lg:text-left">
        <div class="row items-center">
            <div class="col md:w-10/12 lg:w-7/12 order-1 lg:order-none mx-auto">
                <h2 class="font-semibold mb-6 text-2xl lg:text-3xl">Your ChangeFinder Pro subscription got expired please renew your subscription.
                    <br />
                    <br />If you already completed the payment then your status will be updated in 2 Hours
                </h2>
                <div class="flex flex-col lg:flex-row mb-6">
                    <div>
                        <img alt="testimonial face" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/4dd9dfec-892e-4f50-a3e2-f1632c8d3133.png" class="mb-4 lg:mb-0 h-8 mx-auto" />
                    </div>
                    <div class="ml-6">
                        <p class="mb-0 font-semibold">Ian Winbrock</p>
                        <p class="text-muted">CEO</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col md:w-4/12">
                        <div class="flex justify-center items-center mb-3 lg:justify-start">
                            <img alt="stat 1" src="https://d1pnnwteuly8z3.cloudfront.net/images/dafc1e05-b0e8-4c6d-b375-4a62333bbd5a/1e379578-053a-45d7-8fa8-8c7b8db0710d.svg" width="30" />
                            <span class="text-lg font-light font-semibold mb-0.5 ml-2">99%</span>
                        </div>
                        <p class="text-muted">Lorem ipsum dolor sit amet</p>
                    </div>
                    <div class="col md:w-4/12">
                        <div class="flex justify-center items-center mb-3 lg:justify-start">
                            <img alt="stat 2" src="https://d1pnnwteuly8z3.cloudfront.net/images/dafc1e05-b0e8-4c6d-b375-4a62333bbd5a/0197d116-d040-4dd4-88a6-ead4a5f74def.svg" width="30" />
                            <span class="text-lg font-light font-semibold mb-0.5 ml-2">85%</span>
                        </div>
                        <p class="text-muted">Lorem ipsum dolor sit amet</p>
                    </div>
                    <div class="col md:w-4/12">
                        <div class="flex justify-center items-center mb-3 lg:justify-start">
                            <img alt="stat 3" src="https://d1pnnwteuly8z3.cloudfront.net/images/dafc1e05-b0e8-4c6d-b375-4a62333bbd5a/06ea00e2-6b30-46f9-bc45-64ca659d5347.svg" width="30" />
                            <span class="text-lg font-light font-semibold mb-0.5 ml-2">93%</span>
                        </div>
                        <p class="text-muted">Lorem ipsum dolor sit amet</p>
                    </div>
                </div>
            </div>
            <div class="col md:w-8/12 lg:w-5/12 mx-auto order-none lg:order-12 mb-12 lg:mb-0">
                <img alt="product" width="700" height="700" loading="lazy" class="mx-auto block" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/f6044b72-22bf-41af-b77c-7ffab06de73f.png" />
            </div>
        </div>
    </div>
</section>
     )}