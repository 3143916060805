import { useEffect } from "react"
import { connect } from 'react-redux';
import Input from "../../components/input";
import Checkbox from "../../components/checkbox";
import { Link } from "react-router-dom"
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import firebase from 'firebase/compat/app';
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import 'firebase/compat/auth';
import store from "../../store";
import { useState } from "react"
let status="C";
//localStorage.setItem('proversion', 'true');
// TODO: Complete error handling
function signOut(e){
    e.preventDefault();
    const auth = getAuth();
    auth.signOut();
}



const Login = function({setNavbar, user}) {
    useEffect(() => {
        setNavbar(true);
    })
    
    const [ emailError, setEmailError ] = useState("");

    useEffect(() => {
        console.log("PAGE TRANSITI.ON", Object.keys(user));
        console.log("USER")
        console.log(user)
        if (user.data) {

            let futureDate= new Date(user.data.exp);
            const currentDate = new Date();
            const isFutureDate = futureDate > currentDate;
            console.log("currentDate"+currentDate);
            console.log("futureDate"+futureDate+"=="+isFutureDate);
            

            if (!isFutureDate) {
                localStorage.setItem('proversion', 'false');

                window.location.replace('#/');
                localStorage.setItem('exp', futureDate);
              }
              else{
                   localStorage.setItem('proversion', 'true');
                   window.location.replace('#/');
                   localStorage.setItem('exp', futureDate);
              }
              
        }
    }, [user])
    function submitLogin(e) {
        const email = e.target[0].value;
        const password = e.target[1].value;
    
        // console.log(email, password);
        
        e.preventDefault();
        const auth = getAuth();
    
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log("sign in", user)
            store.dispatch({ type: "UPDATE_USER", payload: user })
            //localStorage.setItem('objectToPass', email);
            //window.location.replace('#/whoweserve/GrantMachine');
            
    
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            status="T"
    
            console.log(errorMessage, errorCode)
        });
    }

    function GoogleLogin(e) {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
    
        signInWithPopup(auth, provider)
            .then(async (userCredential) => {

                const { user, additionalUserInfo, credential } = userCredential;
                console.log("User:", user);
                setEmailError("")
                // Check if user is new
                if (user.metadata.creationTime === user.metadata.lastSignInTime) {
                    console.log("New User");
                    // Handle new user logic here
                    setEmailError("Welcome! You've successfully signed up using Google.");
                    const currentDate = new Date();
  
                    // Add 3 months to the current date
                    currentDate.setDate(currentDate.getDate() + 10);
                    
                    const dateString = currentDate.toDateString();
                    const uemail=user.email;
                    const gtype="non-profit";
                    const name = "";
                    const yearFounded = "";
                    const mission = "";
                    const EIN = "";
                    const NTEE ="";
                    const docRef = await addDoc(collection(db, "users"), {
                        type: gtype,
                        email: uemail,
                        name: name,
                        yearFounded: yearFounded,
                        mission: mission,
                        EIN: EIN,
                        NTEE: NTEE,
                        exp: dateString,
                        pro: true
                        //State: "disable"
                        
                    });
                    localStorage.setItem('exp', currentDate);
                    localStorage.setItem('proversion', 'true');
                    store.dispatch({ type: "UPDATE_USER", payload: user })
                    window.location.replace('#/whoweserve/GrantMachine');
                    console.log(docRef);
                } else {
                    console.log("Returning User");
                    // Handle returning user logic
    
                    // I'm assuming you're fetching some data for the user here.
                    // Ensure you handle this part properly.
                    if (user.data) {
                        let futureDate = new Date(user.data.exp);
                        const currentDate = new Date();
                        store.dispatch({ type: "UPDATE_USER", payload: user })
                        if (futureDate <= currentDate) {
                            localStorage.setItem('proversion', 'true');
                            
                            window.location.replace('#/whoweserve/GrantMachine');
                        } else {
                            localStorage.setItem('proversion', 'false');
                            window.location.replace('#/');
                        }
                        localStorage.setItem('exp', futureDate);
                    }
                }
            })
            .catch((error) => {
                console.error("Error signing in with Google:", error);
                handleAuthError(error);
            });
    }
    
    function handleAuthError(error) {
        const { code, message } = error;
    
        // Provide clear messages based on the type of error
        switch (code) {
            case "auth/popup-closed-by-user":
                setEmailError("The popup was closed before authentication could complete. Please try again.");
                break;
            case "auth/popup-blocked":
                setEmailError("The popup was blocked by the browser. Please allow popups and try again.");
                break;
            case "auth/cancelled-popup-request":
                setEmailError("Multiple popups were triggered. Please try the operation again.");
                break;
            default:
                setEmailError(message);
                break;
        }
    }
    const setEmailToNull=()=>{
        setEmailError("")
      }
      
    return (

<>
<div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
<a href="#" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
    <img class="w-8 h-8 mr-2" src="https://d1pnnwteuly8z3.cloudfront.net/images/9faa0c18-fdc0-4286-bb20-a4131d9ef9b6/d9ba458e-f2c2-4311-8fb4-d6fa1dcf09ca.png" alt="logo" /> ChangeFinder
</a>
<div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 md:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
    <div class="p-6 space-y-4 md:space-y-6 md:p-8">
        <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Log in to your account</h1>
        <form class="space-y-4 md:space-y-6" onSubmit={submitLogin}>
            <div>
                <label for="email" class="block mb-2 text-md font-medium text-gray-900 dark:text-white" >Your email</label>
                <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 md:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" error={emailError} displayError/>
            </div>
            <div>
                <label for="password" class="block mb-2 text-md font-medium text-gray-900 dark:text-white">Password</label>
                <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 md:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" password/>
            </div>
            {emailError ?(
            <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold">Holy smokes!</strong><br/>
                <span class="block sm:inline">{emailError}</span>
                <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" onClick={setEmailToNull}><title >Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
            </div>):null}
            <div class="flex items-center justify-between">
                <div class="flex items-start">
                    <div class="flex items-center h-5">
                        <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                    </div>
                    <div class="ml-3 text-md">
                        <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                    </div>
                </div>
                <a href="#" class="text-md font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
            </div>
            <button className="flex items-center justify-center px-4 py-2 bg-black hover:bg-gray-900 text-white border border-black rounded-md shadow-md w-full mt-4" onClick={GoogleLogin}>
                <img src="https://www.google.com/favicon.ico" alt="Google Icon" className="mr-2" width="20" height="20" />
                    Google SignIn
            </button>
            <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-md px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
            <p class="text-md font-light text-gray-500 dark:text-gray-400">Don’t have an account yet? <Link to="/register" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Register now</Link>
            </p>
        </form>
    </div>
</div>
</div>
</>
    )
}

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(Login);
