import { useState } from 'react';

export default function Input(props) {
    const [ value, setValue ] = useState(props.defaultValue || "");

    let onInputChange = (e) => setValue(e.target.value);

    return (
        <div className="input-group">
            <input 
                id={props.label} 
                required={props.required} 
                className={`input-group__input ${props.error && "input-group__input--error"}`} 
                placeholder=" " 
                onChange={onInputChange}
                value={value}
                type={props.password ? ("password") : ("input")} 
                />
            <label htmlFor={props.label} className={`input-group__label ${props.error && "input-group__label--error"}`} >{props.label}{props.required && props.register && "*"}</label>
            { props.error && props.displayError && 
                <p className="input-group__error" >{props.error}</p>
            }
        </div>
    )
}