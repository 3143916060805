import { connect } from 'react-redux';
import { useState } from "react"
import Input from "../../components/input";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { getAuth } from "firebase/auth";

//const docRef = doc(db, "users");

const mapStateToProps = (props) => (props);

// TODO: Complete error handling
export default connect(mapStateToProps)(function Editprofile() { 
    let [ accType, setAccType ] = useState("");
    const submitAccType = (e, types) => {
        e.preventDefault();
        console.log(types);
        setAccType(types)
    }
    const auth = getAuth();
    const user = auth.currentUser;
    let name=user.data.name;
    let yearFounded=user.data.yearFounded;
    let mission=user.data.mission;
    let EIN=user.data.EIN;
    let NTTE=user.data.NTTE;





    
    const submitDetails = async (e) => {
        e.preventDefault();
        const type = accType;
        //const name =e.target[1].value;
        const name =e.target[2].value;
        user.data.name=e.target[2].value;
        const yearFounded = e.target[3].value;
        user.data.yearFounded= e.target[3].value;
        const mission = e.target[4].value;
        user.data.mission= e.target[4].value;
        const EIN = e.target[5].value;
        user.data.EIN = e.target[5].value;
        const NTEE = e.target[6].value;
        user.data.NTTE= e.target[7].value;
        console.log(type, name);
        console.log(yearFounded);
        console.log(type,yearFounded,mission,EIN,NTEE);
        console.log(type,yearFounded,mission,EIN,NTEE);
        const docRef = await addDoc(collection(db, "users"), {
            type: type,
            name: name,
            yearFounded: yearFounded,
            mission: mission,
            EIN: EIN,
            NTEE: NTEE
        });
        console.log(docRef);

        window.location.replace('/#/whoweserve/GrantMachine');
    }
    return (
        <div className="reset">
            <main>
                <div className="reset__box">
                <div className="reset__form">
                <form onSubmit={submitDetails}>
                    <h1>Edit details</h1>
                    <h2>Choose account type</h2>
                    <center>
                    <table border="3" className="reset__table">
                        <tr>
                            <td><b>Name: </b></td>
                            <td>{user.data.name}</td>
                        </tr>
                        <tr>
                            <td><b>Email: </b></td>
                            <td>{user.email}</td>
                        </tr>
                        <tr>
                            <td><b>Year Foundation: </b></td>
                            <td>{user.data.yearFounded}</td>
                        </tr>
                        <tr>
                            <td><b>Mission Statement: </b></td>
                            <td>{user.data.mission}</td>
                        </tr>
                        <tr>
                            <td><b>EIN: </b></td>
                            <td>{user.data.EIN}</td>
                        </tr>
                        <tr>
                            <td><b>NTEE Code: </b></td>
                            <td>{user.data.NTEE}</td>
                        </tr>

                        </table>
                        </center>
                                <div className="acctype">
                                    <button className="acctype__type acctype__type--non-profit" onClick={(e) => submitAccType(e, "non-profit")}>
                                        <div className="acctype__background--non-profit"></div>
                                        <img className="acctype__img" alt="Non-profits" src="https://images.unsplash.com/photo-1643321610692-719deb378a33?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" />
                                        <h2 className="acctype__subtitle">Non-Profit</h2>
                                        <h2 className="acctype__subtitle--background">Non-Profit</h2>
                                    </button>
                                    <button className="acctype__type acctype__type--foundation" onClick={(e) => submitAccType(e, "foundation")}>
                                        <div className="acctype__background--foundation"></div>
                                        <img className="acctype__img" alt="Foundations" src="https://images.unsplash.com/photo-1643321613132-040da599025b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" />
                                        <h2 className="acctype__subtitle">Foundation</h2>
                                        <h2 className="acctype__subtitle--background">Foundation</h2>
                                    </button>
                                </div>
                        <Input label={`Name`}  register/>
                        <Input label={`Year Founded`}  register/>
                        <Input label={`Mission Statement`}  register/>
                        <Input label={`EIN`}  register/>
                        <Input label={`NTEE code`}  register/>
                        <div className="login__bottom">
                            <button className="btn" onclick="window.location.replace('/reset');">Reset Password</button>
                            <button className="btn" type="submit">Set</button>
                        </div>
                </form>
                
                </div>
                </div>
            
            </main>
        </div>
    )
})