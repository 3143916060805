import { Outlet} from "react-router-dom";
import NavbarNonprofit from '../../components/navbarNonprofit';
import Footer from '../../components/footer';

export default function Layout(props) {

    return (
        <div className="layout">
            <NavbarNonprofit />
            <Outlet />
            <Footer minmode />
        </div>
    )
}