import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

import Logo from '../img/LogoWhite.PNG';
import '../utils/firebase';
import MobileMenu from './MobileMenu';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
        };
        this.auth = getAuth();
    }

    componentDidMount() {
        this.auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({ loggedIn: true });
            } else {
                this.setState({ loggedIn: false });
            }
        });
    }

    handleSignOut = () => {
        this.auth.signOut();
    };

    render() {
        const { loggedIn } = this.state;
        return (
            <div className="container flex py-2">
                <a className="navbar-brand flex-shrink-0 text-xl" href="/">
                    <img src={Logo} width="60" height="60" alt="company logo" />
                </a>

                <MobileMenu />

                <div className="navbar-row" id="navbar-row">
                    <ul className="navbar-col">
                        <li><a className="nav-link font-semibold h-fit text-3xl px-2" href="#/whoweserve/features">Features</a></li>
                        <li><a className="nav-link font-semibold text-3xl px-2" href="#/payment">Pricing</a></li>
                        <li><a className="nav-link font-semibold text-3xl px-2" href="#/whoweserve/solution">Solutions</a></li>
                        {loggedIn && (
                            <li><a className="nav-link font-semibold text-3xl px-2" href="#/whoweserve/GrantMachine">GrantMachine</a></li>
                        )}
                    </ul>
                    <ul className="navbar-col">
                        {loggedIn ? (
                            <li><a className="nav-link font-semibold text-2xl px-1" href="#/" onClick={this.handleSignOut}>SignOut</a></li>
                        ) : (
                            <li><a className="nav-link font-semibold text-3xl px-2" href="#/login">Login</a></li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(Navbar);
