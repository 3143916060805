export default function Government(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>Government grant programs can have tremendous impact in the communities they serve, but can be difficult and expensive to manage. Often the systems that are created for grant programs are obsolete and not inclusive of the communities that need them the most. ChangeFinder’s solution takes your existing process and applications and uploads them to our system, matching you to the most relevant nonprofits for your giving opportunities so that you can focus on advancing your vision with the organizations that are most capable of making a difference and who best match your selection criteria. Our easy to understand and use software is easily customizable to fit your needs and make it simple for you to create grant applications, manage grant submissions, share submissions with reviewers and get effective reports. We work with you to ensure that each part of your grant process works for you and is customized to fit the needs of your agency, city or county and the nonprofits that you fund.</p>

                <p>Whether you have been funding organizations and solutions for decades, or are just getting started, ChangeFinder’s grant management software will make your giving processes more efficient, saving your organization time and money while increasing your impact. </p>

                </div>
            </div>
        </main>
    </div>
 )}