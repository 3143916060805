import React from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

export default function Dropzone(props) {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            accept: { "application": [".txt,.pdf,.docx,.doc"] },
            onDropAccepted: (files) => props.process(files[0]),
        });

    const files = acceptedFiles.map((file) => (
        <span key={file.path}>
            {file.path} - {file.size} bytes
        </span>
    ));

    return (
        <div className="border rounded mb-5 p-5">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <FontAwesomeIcon
                    icon={faDownload}
                    size="2xl"
                    className="mb-4 pointer"
                />
                {isDragActive ? (
                    <p>Drop the txt file here ...</p>
                ) : (
                    <p className="pointer">
                        Drag &apos;n&apos; drop some files here, or click to select txt
                    </p>
                )}
                <span className="btn btn-primary btn-lg px-5 mb-4">Select a file</span>
            </div>
            {files.length > 0 && <p>File: {files[0]}</p>}
        </div>
    );
}
Dropzone.propTypes = {
    process: PropTypes.func,
};
