import { Outlet} from "react-router-dom";
import NavbarFoundation from '../../components/navbarFoundation';
import Footer from '../../components/footer';

export default function Layout(props) {

    return (
        <div className="layout">
            <NavbarFoundation />
            <Outlet />
            <Footer minmode />
        </div>
    )
}