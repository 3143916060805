import Home from "./pages/home/home";
import Paymentstatus from "./pages/home/paymentstatus";
import Platform from "./pages/platform/platform";

// import Whoweserve from "./pages/whoweserve/whoweserve";
import Foundations from "./pages/whoweserve/foundations";
import Subscribtionexpired from "./pages/whoweserve/subscribtionexpired";
import Corporate from "./pages/whoweserve/corporate";
import Nonprofits from "./pages/whoweserve/nonprofits";
import Government from "./pages/whoweserve/government";
import Features from "./pages/whoweserve/features";
import Donors from "./pages/whoweserve/donors";
import Jobseekers from "./pages/whoweserve/jobseekers";
import GrantChecker from "./pages/whoweserve/GrantChecker";
import GrantMachine from "./pages/whoweserve/GrantMachine";
import Company from "./pages/company/company";
import Advisors from "./pages/company/advisors";
import Careers from "./pages/company/careers";
import Mission from "./pages/company/mission";
import Team from "./pages/company/team";
import Footer from "./pages/company/footer";

// import Resources from "./pages/resources/resources";
// import Blog from "./pages/resources/blog";

import Grants from './pages/grants/grants'
import Profile from './pages/whoweserve/profile'

import Login from "./pages/login/login";
import Register from "./pages/login/register";
import Reset from "./pages/login/reset";
import Payment from "./pages/login/payment";
import Success from "./pages/login/success";
import Bills80 from "./pages/login/bills80";
import Bills800 from "./pages/login/bills800";
import Editprofile from "./pages/login/editprofile";
import LoginRequest from "./pages/login/loginRequest";
import Layout from "./pages/layout/layout";
import LayoutFoundation from "./pages/layout/layoutFoundation";
import LayoutNonprofit from "./pages/layout/layoutNonprofit";
import Privacy from "./pages/company/privacy";
import Licensing from "./pages/company/licensing";
import UnitedWayGrantReviewer from "./pages/whoweserve/unitedWayGrantReviewer";
import NoPage from "./pages/nopage/nopage";
import Construction from "./pages/nopage/construction";
import { HashRouter, Routes, Route } from "react-router-dom";
import { useState } from "react"

import { connect } from 'react-redux';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "./store";

import "./utils/firebase";
import Whoweserve from "./pages/whoweserve/solution";
import Solution from "./pages/whoweserve/solution";

function App(props) {
  const [navbar, setNavbar] = useState(false);
  const [loggedin, setLoggedin] = useState(false);

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
      if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          if (props.user !== user) {
            console.log("UID:", uid);
            setLoggedin(true)
            store.dispatch({ type: "UPDATE_USER", payload: user })
          }
          // ...
      } else {
          // User is signed out
          if (Object.keys(props.user).length > 0) {
            console.log(props.user)
            store.dispatch({ type: "SET_USER", payload: {} })
          }
          // ...
      }
  });

  const user = props.user

  return (
    <div className="App">
        <HashRouter>
          
          <Routes>
            {user.uid &&
              <Route path="/profile" element={<Layout navbar={navbar} loggedin={loggedin} />}>
                <Route index element={<Construction setNavbar={setNavbar} navbar={navbar} />} />
                <Route path="analytics" element={<Construction setNavbar={setNavbar} />} />
              </Route>
            }
            <Route path="/whoweserve" element={<Layout navbar={true} />}>
              <Route index element={<Whoweserve />} />
              <Route path="foundations" element={<Foundations />} />
              <Route path="corporate" element={<Corporate />} />   
              <Route path="nonprofits" element={<Nonprofits />} />
              <Route path="government" element={<Government />} />
              <Route path="features" element={<Features />} />
              <Route path="donors" element={<Donors />} />
              <Route path="jobseekers" element={<Jobseekers />} />
              <Route path="GrantChecker" element={<GrantChecker />} />
              <Route path="GrantMachine" element={<GrantMachine />} />
              <Route path="solution" element={<Solution />} />
              <Route path="unitedWayGrantReviewer" element={<UnitedWayGrantReviewer />} />
              <Route path="subscribtionexpired" element={<Subscribtionexpired />} />
             </Route>

            <Route path="/company" element={<Layout navbar={true} />}>
              <Route index element={<Construction />} />
              <Route path="mission" element={<Mission />} />
              <Route path="team" element={<Team />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="licensing" element={<Licensing />} />
              <Route path="advisors" element={<Advisors />} />
              <Route path="careers" element={<Careers />} />
            </Route>
            <Route path="/resources" element={<Layout navbar={true} />}>
              <Route index element={<Construction />} />
              <Route path="blog" element={<Construction  />} />
            </Route>
            <Route path="/np" element={<LayoutNonprofit />}>
              <Route index element={<Construction long />} />
              <Route path="grants" element={<Grants long />} />
              <Route path="analytics" element={<Construction long />} />
            </Route>
            <Route path="/fd" element={<LayoutFoundation />}>
              <Route index element={<Construction long />} />
              <Route path="grants" element={<Construction long />} />
              <Route path="analytics" element={<Construction long />} />
            </Route>
            <Route path="/" element={<Layout navbar={navbar} user={user} />}>
              <Route index element={<Home setNavbar={setNavbar} navbar={navbar}/>} />
              <Route index element={<Paymentstatus setNavbar={setNavbar} navbar={navbar}/>} />
              <Route path="login" element={<Login setNavbar={setNavbar} />} />
              <Route path="loginRequest" element={<LoginRequest setNavbar={setNavbar} />} />
              <Route path="register" element={<Register setNavbar={setNavbar} />} />
              <Route path="reset" element={<Reset setNavbar={setNavbar} />} />
              <Route path="payment" element={<Payment setNavbar={setNavbar} />} />
              <Route path="success" element={<Success setNavbar={setNavbar} />} />
              <Route path="bills80" element={<Bills80 setNavbar={setNavbar} />} />
              <Route path="bills800" element={<Bills800 setNavbar={setNavbar} />} />
              <Route path="editprofile" element={<Editprofile setNavbar={setNavbar} />} />
              <Route path="platform" element={<Platform setNavbar={setNavbar} />} />
              <Route path="*" element={<NoPage setNavbar={setNavbar} />} />
            </Route>
            <Route path="/user" element={<Layout navbar={navbar} user={user} />} >
              <Route path="profile" element={<Profile />} />
            </Route>
          </Routes>
          <Footer/>
        </HashRouter>
    </div>
  );
}

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(App);
