export default function Features(props) {
    return ( 
        <>
        <section class="py-28" id="header">
    <div class="container">
        <div class="row justify-center">
            <div class="col lg:w-8/12 text-center md:w-10/12">
                <h4 class="h1 font-semibold 2xl:active:text-center">GrantMachine edits and writes grants on demand
                </h4>
                <a href="#/login" class="inline-block px-4 bg-[#549C30] hover:bg-[#1d4728] text-5xl font-semibold text-white rounded-lg">Start FREE trial ➔</a>
            </div>
        </div>
        </div>
        </section>



    <section class="py-20 bg-gradient-to-br from-secondary-100 via-primary-100 to-primary-100" id="features">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="display-6 font-semibold text-center text-dark">Let GrantMachine do the work
                        <br />
                    </h2>
                </div>
            </div>
            <div class="row mt-6">
                <div class="col md:w-4/12 mt-6">
                    <div class="shadow-lg rounded px-6 py-12 bg-white">
                        <div class="flex items-center justify-center rounded-full h-16 w-16 bg-dark">
                            <i class="text-white text-3xl fas fa-lightbulb fa-3x"></i>
                        </div>
                        <p class="font-semibold mt-4 mb-3 h4">Upload your grant and ideas
                            <br />
                        </p>
                        <p class="mb-12 leading-6">Upload your grant questions, answers and leave the rest to us.

Upload your existing grant materials and ideas, and witness the magic as our GrantMachine transforms them into polished, persuasive narratives.
</p>
                        <a class="font-semibold" href="#">Learn More <i class="fas fa-arrow-right pl-2"></i>
                        </a>
                    </div>
                </div>
                <div class="col md:w-4/12 mt-6">
                    <div class="shadow-lg rounded px-6 py-12 bg-white">
                        <div class="flex items-center justify-center rounded-full h-16 w-16 bg-dark">
                            <i class="text-white text-3xl fas fa-newspaper fa-3x"></i>
                        </div>
                        <p class="font-semibold mt-4 mb-3 h4">Get high-level feedback and recommended changes</p>
                        <p class="mb-12 leading-6">Elevate your grant from good to great!

GrantMachine will provide you with easy to understand feedback and recommended enhancements so that your proposal resonates with funders and stands out in the crowd.</p>
                        <a class="font-semibold" href="#">Learn More <i class="fas fa-arrow-right pl-2"></i>
                        </a>
                    </div>
                </div>
                <div class="col md:w-4/12 mt-6">
                    <div class="shadow-lg rounded px-6 py-12 bg-white">
                        <div class="flex items-center justify-center rounded-full h-16 w-16 bg-dark">
                            <i class="text-white text-3xl fas fa-robot fa-3x"></i>
                        </div>
                        <p class="font-semibold mt-4 mb-3 h4">Let our AI write for you</p>
                        <p class="mb-12 leading-6">Now you have a professional grant writer on demand! 

When you let GrantMachine, your AI co-writer take the reins, it will craft you compelling grant responses while you stay in control.</p>
                        <a class="font-semibold" href="#">Learn More <i class="fas fa-arrow-right pl-2"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section></>
     );
}