// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqOAUDQICZJpEqhWiSAmeaCY_BqWpNCYw",
  authDomain: "changefinder-c1c14.firebaseapp.com",
  projectId: "changefinder-c1c14",
  storageBucket: "changefinder-c1c14.appspot.com",
  messagingSenderId: "567347716493",
  appId: "1:567347716493:web:8caa1e79b90bbd95caddc4",
  measurementId: "G-HWQERZESKW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const firebase = initializeApp(firebaseConfig);

