export default function Advisors(props) {
    return ( 
        <div className='page'>
            <main>
                <div className="page__box">
                    <div className="page__page">
                    Check back soon!
                    </div>
                </div>
            </main>
        </div>
     );
}