export default function Mission(props) {
return ( 
    <div className='page'>
        <main>
            <div className="page__box">
                <div className="page__page">
                <p>Empowering philanthropy, nonprofits, and the communities they serve to solve the world’s most pressing challenges by improving grant application success rates.</p>
                </div>
            </div>
        </main>
    </div>
 );
}