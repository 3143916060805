import { useState } from "react"
import { useEffect } from "react"
import { connect } from 'react-redux';
import Input from "../../components/input";
import { getAuth } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";

const Profile = function(props) {
    const [user, setUser] = useState({});
    //const [email, setEmail] = useState({});
    useEffect(() => {
        console.log("PAGE TRANSITION", props.user);
        setUser(props.user)
    }, [props.user])
    const auth = getAuth();
    const users = auth.currentUser;
    let name=users.data.name;
    let email=props.user.email;
    let yearFounded=users.data.yearFounded;
    let mission=users.data.mission;
    let EIN=users.data.EIN;
    let NTTE=users.data.NTEE;
    console.log("Years:",yearFounded,"Mission:",mission,"EIN:",EIN,"NTTE",NTTE);


    // TODO: Complete error handling and everything else
const onSubmit = (e) =>  {
    e.preventDefault();
    //const name =e.target[1].value;
    const Email =e.target[0].value;

    const yearFounded = e.target[1].value;

    const EIN = e.target[2].value;

    const NTEE = e.target[3].value;

    const mission=e.target[4].value;
    //const image=e.target[5].value;


    props.user.email=Email;
    users.data.yearFounded=yearFounded;
    users.data.mission=mission;
    users.data.EIN=EIN;
    users.data.NTEE=NTEE;
    console.log(e.target[0].value,e.target[1].value,e.target[2].value,e.target[3].value,e.target[4].value);
    let count = 0;
    // const ref = db.ref('server/saving-data/fireblog/posts');

    // ref.on('QnA', (snap) => {
    //     count++;
    //     console.log('added:', snap.key);
    // });


}

    


    if (props.user.data) {
        return (
            <main className="profile">

                <div className='login__box'>
                    
                    <form onSubmit={onSubmit} className="login__form">
                    <h1 className="bold my-5">{name}</h1>
                    <h3 className="bold my-5">founded in {yearFounded}</h3>

                        <Input label="Email" defaultValue={email}/>
                        <Input label="Ruling Year" defaultValue={yearFounded}/>
                        <Input label="EIN" defaultValue={EIN} />
                        <Input label="NTEE code(s)" defaultValue={NTTE}/>
                        <Input label="Mission" defaultValue={mission} />
                        <button className='btn' type="submit" >Save</button>
                    </form>  
                    <h4>{}</h4>

                </div>    
            </main>
        )
    } else return <main className="profile"></main>
}

const mapStateToProps = (props) => (props);

export default connect(mapStateToProps)(Profile);