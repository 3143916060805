import { connect } from 'react-redux';
import { useState } from "react"
import Input from "../../components/input";
import { getAuth, updatePassword } from "firebase/auth";
const mapStateToProps = (props) => (props);

// TODO: Complete error handling
export default connect(mapStateToProps)(function Reset({setNavbar, user}) { 
    let [ passwordError, setPasswordError ] = useState("");
    const submitReset = (e) => {
        e.preventDefault();
        const auth = getAuth();
        
        
        const OldPassword= e.target[0].value;
    
        const newPassword1 = e.target[1].value;
        const newPassword2 = e.target[2].value;
    
        if (newPassword1 !== newPassword2) {
            console.log("Passwords do not match");
            setPasswordError("Passwords do not match");
            return;
        }
    
        if (OldPassword === newPassword2) {
            console.log("Old Passwords and New password Should not be same");
            setPasswordError("Old Passwords and New password Should not be same");
            return;
        }
        const user = auth.currentUser;
        
        updatePassword(user, newPassword1).then(() => {
            const user1 = user.user;
            console.log(user1)
            alert("Success");
        }).catch((error) => {
            console.log(error)
            setPasswordError(error.message);
        });
    }

    return (
        <div className="reset">
            <main>
                <div className="reset__box">
                    <form className="reset__form" onSubmit={submitReset}>
                        <h1>Reset Password</h1>
                        <Input label="Old Password" required password error={passwordError}/>
                        <Input label="New Password" required password/>
                        <Input label="ReEnter New Password" required displayError password error={passwordError}/>
                        <div className="reset__bottom">
                            <button className="btn" type="submit">Change</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    )
})